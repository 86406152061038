import React, { Component } from "react";
import { Container } from "reactstrap";
import GatewayProperties from "../components/Forms/GatewayProperties";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class EditGatewayProperties extends Component {
  render() {
    const gatewayInfo = this.props.location.gateway;
    if (!gatewayInfo) {
      return "";
    }
    return (
      <>
        <Container fluid className="mt--9">
          <GatewayProperties {...this.props.store} />
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { gatewayGuid } = ownProps.location.gateway;
  const gw = state.gateways.byId[gatewayGuid];

  let controllers = {}

  Object.keys(state.controllers.byId).forEach((controllerGuid) => {
    if (state.controllers.byId[controllerGuid].gateway.gatewayGuid === gatewayGuid)
      controllers[controllerGuid] = state.controllers.byId[controllerGuid];
  });

  return {
    store: {
      site: state.sites.byId[gw.site.siteGuid],
      gateway: { ...state.gateways.byId[gatewayGuid], devices: Object.values(controllers) }
    }
  };
};

export default withTranslation("common")(
  connect(mapStateToProps, null)(EditGatewayProperties)
);
