import { UserAgentApplication, Logger, LogLevel } from "msal";
import axios from "axios";
import utils from "utils";
import keys from "configs/constants";
import { Store as notifyStore } from "react-notifications-component";
import { tempOptions, NotifyContent } from "../components/Notify";
import { store as reduxStore } from "index.js";
import { removeAlert } from "../redux/actions/index";

const BASE_API_URL = keys.API_URL;
const API_VERSION = "/api/v1/";

const DEFAULT_LIMIT = 25;

const API_GET = "API_GET";
const API_POST = "API_POST";
const API_PUT = "API_PUT";
const API_DELETE = "API_DELETE";

const msalConfig = {
  auth: {
    clientId: keys.B2C_APPID,
    authority: `https://${keys.B2C_TENANT}.b2clogin.com/tfp/${keys.B2C_TENANT}.onmicrosoft.com/${keys.B2C_SIGNUP_SIGNIN_POLICY}`,
    validateAuthority: false,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  scopes: [keys.B2C_SCOPE],
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies
  },
  system: {
    logger: new Logger(
      (logLevel, message) => {
        switch (logLevel) {
          case LogLevel.Error:
            utils.debug(message, utils.MSG_TYPE_ERR);
            return;
          case LogLevel.Warning:
            utils.debug(message, utils.MSG_TYPE_WARN);
            return;
          case LogLevel.Info:
          case LogLevel.Verbose:
          default:
            return;
        }
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
        correlationId: "1234",
      }
    ),
  },
};

export const ResetPasswordPolicy = `https://${keys.B2C_TENANT}.b2clogin.com/tfp/${keys.B2C_TENANT}.onmicrosoft.com/${keys.B2C_PASSWORD_RESET_POLICY}`;
export const UpdateUserProfilePolicy = `https://${keys.B2C_TENANT}.b2clogin.com/tfp/${keys.B2C_TENANT}.onmicrosoft.com/${keys.B2C_PROFILE_EDIT}`;

export const msalAuth = new UserAgentApplication(msalConfig);

const TOKEN_REFRESH_LIFETIME = 300 * 1000; // Seconds
var authHeader = null;
var tokenExpiredOn = 0;

const callMappAPI = async (method, endpoint, body = {}) => {
  const url = `${keys.MOBILE_API_URL}/${endpoint}`;
  const result = {};

  const request = {};
  request.method = method;
  request.headers = {
    "Content-Type": "application/json; charset=utf-8",
    "Ocp-Apim-Subscription-Key": keys.API_KEY,
  };
  if (body) {
    request.body = JSON.stringify(body);
  }

  const response = await fetch(url, request);
  result.status = response.status;
  result.object = null;
  result.responseString = "";

  if (!response.ok) {
    if (!keys.IS_PROD) {
      response.text().then((text) => {
        utils.debug(`API Response: ${text}`, utils.MSG_TYPE_WARN);
      });
    }
    result.responseString = (
      response.statusText ||
      response.status ||
      "Unknown Error"
    ).toString();
    return Promise.reject(result);
  }
  if (response.status !== 204) {
    result.object = await response.json();
    result.responseString = (
      response.object ||
      response.statusText ||
      response.status ||
      "Unknown Error"
    ).toString();
  }
  if (!keys.IS_PROD) {
    utils.debug(
      `API Response Body: ${response.status} ${result.responseString}`,
      utils.MSG_TYPE_INFO
    );
  }
  return Promise.resolve(result);
};

export const getAPICallAuthHeader = async (config = null) => {
  const now = new Date().getTime();

  if (tokenExpiredOn !== 0 && now >= new Date(tokenExpiredOn).getTime()) {
    msalAuth.logout({});
    return "logout";
  }

  if (
    tokenExpiredOn > 0 &&
    new Date(tokenExpiredOn).getTime() - now <= TOKEN_REFRESH_LIFETIME
  )
    clearSessionStorage();

  if (tokenExpiredOn === 0 || authHeader === null) {
    let i = 0;
    if (config !== null) authHeader = config;
    else {
      while (authHeader === null && i < 10) {
        authHeader = await acquireToken();
        if (authHeader === "logout") return;
        i++;
      }
    }
  }

  if (authHeader !== null) authHeader["validateStatus"] = false;
  return authHeader;
};

export const acquireToken = async () => {
  return new Promise((resolve, reject) => {
    msalAuth
      .acquireTokenSilent(msalConfig)
      .then((accessToken) => {
        tokenExpiredOn = accessToken.expiresOn;
        utils.debug("Expires at " + new Date(tokenExpiredOn).toLocaleString());
        resolve({
          headers: {
            Authorization: "Bearer " + accessToken.accessToken,
          },
        });
      })
      .catch(async (error) => {
        if (error.errorCode === "token_renewal_error") {
          clearSessionStorage();
          resolve(acquireToken());
        } else if (error.errorCode === "interaction_required") {
          await msalAuth.acquireTokenRedirect(msalConfig);
          resolve(null);
        } else {
          // Error not managed, reject
          utils.debug(error, utils.MSG_TYPE_WARN);
          reject(error);
        }
      });
  });
};

export const clearSessionStorage = () => {
  tokenExpiredOn = 0;
  authHeader = null;
  Object.keys(sessionStorage)
    .filter((x) => x.indexOf("authority") > 0)
    .forEach((x) => sessionStorage.removeItem(x));
};

const _getWithIteration = async (base, limit = DEFAULT_LIMIT) => {
  // No need for limit parameter if limit is DEFAULT_LIMIT
  let url =
    limit === DEFAULT_LIMIT
      ? base
      : base.includes("?")
        ? base + "&limit=" + limit
        : base + "?limit=" + limit;

  // Call the first time and get total expected data
  let out = await callAPI(url);

  // Build the array of requests to be made in parallel
  let urls = [];
  let iteration = Math.floor(out.data.total / limit); // Keep in mind one call has been already done
  let skip = 1;

  while (skip <= iteration) {
    let tmp_url = url.includes("?")
      ? url + "&offset=" + skip * limit
      : url + "?offset=" + skip * limit;
    skip += 1;
    urls.push(tmp_url);
  }
  let conf = await getAPICallAuthHeader();

  await axios
    .all(urls.map((url) => axios.get(url, conf)))
    .then((data) => {
      data.forEach((d) => {
        out.data.data = out.data.data.concat(d.data.data);
      });
    })
    .catch((error) => {
      utils.debug(error, utils.MSG_TYPE_WARN);
      return error;
    });

  return out;
};

// ASCII Arts from here: http://patorjk.com/software/taag/#p=display&f=Epic&t=Gateway
//  _______           _______ _________ _______  _______  _______  _______  _______
// (  ____ \|\     /|(  ____ \\__   __/(  ___  )(       )(  ____ \(  ____ )(  ____ \
// | (    \/| )   ( || (    \/   ) (   | (   ) || () () || (    \/| (    )|| (    \/
// | |      | |   | || (_____    | |   | |   | || || || || (__    | (____)|| (_____
// | |      | |   | |(_____  )   | |   | |   | || |(_)| ||  __)   |     __)(_____  )
// | |      | |   | |      ) |   | |   | |   | || |   | || (      | (\ (         ) |
// | (____/\| (___) |/\____) |   | |   | (___) || )   ( || (____/\| ) \ \__/\____) |
// (_______/(_______)\_______)   )_(   (_______)|/     \|(_______/|/   \__/\_______)
//
/* Customer is like "Lidl Italia", "Mc Donalds", etc etc */
const getCustomersDetails = async (customerGUID = null) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "customer" +
    (customerGUID !== null ? "/" + customerGUID : "");
  if (customerGUID) return await callAPI(url);
  else return await _getWithIteration(url);
};

const getCustomersInitialState = async () => {
  const customers = (await getCustomersDetails()).data.data;

  const customersById = {};

  customers.forEach((customer) => {
    customersById[customer.customerGuid] = {
      ...customer,
    };
  });

  return { byId: customersById, allIds: customers.map((x) => x.customerGuid) };
};

const addCustomer = (cName) => {
  let par = { name: cName };
  return callAPI(BASE_API_URL + API_VERSION + "customer", API_POST, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteCustomer = (cGUID, hard = false) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "customer/" +
      cGUID +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const updateCustomer = (cName, cGUID) => {
  let par = { name: cName, customerGuid: cGUID };
  return callAPI(BASE_API_URL + API_VERSION + "customer", API_PUT, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// Only Admin!
const getCustomersFull = async (includeDeleted = true) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "customer/full?includeDeleted=" +
    includeDeleted;

  return await _getWithIteration(url);
};
//  _______ __________________ _______  _______
// (  ____ \\__   __/\__   __/(  ____ \(  ____ \
// | (    \/   ) (      ) (   | (    \/| (    \/
// | (_____    | |      | |   | (__    | (_____
// (_____  )   | |      | |   |  __)   (_____  )
//       ) |   | |      | |   | (            ) |
// /\____) |___) (___   | |   | (____/\/\____) |
// \_______)\_______/   )_(   (_______/\_______)
//

const getSiteDetails = async (siteGUID) => {
  return await callAPI(BASE_API_URL + API_VERSION + "site/" + siteGUID);
};

const getSites = async (customerGUID = null, includeDeleted = false) => {
    const baseUrl = `${BASE_API_URL}${API_VERSION}site`;

    const params = new URLSearchParams();
    if (customerGUID) params.append("customerGUID", customerGUID);
    if (includeDeleted) params.append("includeDeleted", "true");

    const url = `${baseUrl}?${params.toString()}`;
    return customerGUID ? await callAPI(url) : await _getWithIteration(url, 100);
};

const addSite = (
  sName,
  sCountry,
  sCity,
  sLatitude,
  sLongitude,
  sCustomerGUID
) => {
  let par = {
    name: sName,
    country: sCountry,
    city: sCity,
    latitude: sLatitude,
    longitude: sLongitude,
    customerGuid: sCustomerGUID,
  };
  return callAPI(BASE_API_URL + API_VERSION + "site", API_POST, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteSite = (sGUID, hard = false) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "site/" +
      sGUID +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const updateSite = (
  sName,
  sCountry,
  sCity,
  sLatitude,
  sLongitude,
  sGUID,
  cGUID
) => {
  let par = {
    name: sName,
    country: sCountry,
    city: sCity,
    latitude: sLatitude,
    longitude: sLongitude,
    siteGuid: sGUID,
    customerGuid: cGUID,
  };
  return callAPI(BASE_API_URL + API_VERSION + "site", API_PUT, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//           _______  _______  _______  _______
// |\     /|(  ____ \(  ____ \(  ____ )(  ____ \
// | )   ( || (    \/| (    \/| (    )|| (    \/
// | |   | || (_____ | (__    | (____)|| (_____
// | |   | |(_____  )|  __)   |     __)(_____  )
// | |   | |      ) || (      | (\ (         ) |
// | (___) |/\____) || (____/\| ) \ \__/\____) |
// (_______)\_______)(_______/|/   \__/\_______)
//

const getUsersDetails = async (userGUID = null, includeDel = false) => {
  let uGUID = userGUID;
  if (uGUID === "current") {
    uGUID = window.sessionStorage.getItem("userGUID");
  }
  let url = BASE_API_URL + API_VERSION + "user";
  let first = true;
  for (const par of [
    { name: "userGuid", val: userGUID },
    { name: "includeDeleted", val: includeDel },
  ]) {
    if (par.val !== null && par.val !== false) {
      if (first === true) {
        first = false;
        url += "?" + par.name + "=" + par.val;
      } else url += "&" + par.name + "=" + par.val;
    }
  }
  if (userGUID) return await callAPI(url);
  else return await _getWithIteration(url, 50);
};

const addUsers = (userArray = []) => {
  return callAPI(BASE_API_URL + API_VERSION + "user/sub", API_PUT, userArray)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteUser = (uGUID, hard = false) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "user/" +
    uGUID +
    (hard ? "?hardDelete=true" : "");
  return callAPI(url, API_DELETE)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//           _______  _______  _______    _______  _______  _        _______
// |\     /|(  ____ \(  ____ \(  ____ )  (  ____ )(  ___  )( \      (  ____ \
// | )   ( || (    \/| (    \/| (    )|  | (    )|| (   ) || (      | (    \/
// | |   | || (_____ | (__    | (____)|  | (____)|| |   | || |      | (__
// | |   | |(_____  )|  __)   |     __)  |     __)| |   | || |      |  __)
// | |   | |      ) || (      | (\ (     | (\ (   | |   | || |      | (
// | (___) |/\____) || (____/\| ) \ \__  | ) \ \__| (___) || (____/\| (____/\
// (_______)\_______)(_______/|/   \__/  |/   \__/(_______)(_______/(_______/
//
const getUserRole = async (userGUID) => {
  return await callAPI(BASE_API_URL + API_VERSION + "user-role/" + userGUID);
};

const deleteUserRole = (userGUID) => {
  return callAPI(
    BASE_API_URL + API_VERSION + "user-role/" + userGUID,
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const assignRoleToUser = (userGUID, userRole) => {
  let par = {
    userGuid: userGUID,
    role: userRole,
  };
  return callAPI(BASE_API_URL + API_VERSION + "user-role", API_POST, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const updateUserRole = (userGUID, userRole) => {
  let par = {
    userGuid: userGUID,
    role: userRole,
  };
  return callAPI(BASE_API_URL + API_VERSION + "user-role", API_PUT, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//  _______  _______ _________ _______           _______
// (  ____ \(  ___  )\__   __/(  ____ \|\     /|(  ___  )|\     /|
// | (    \/| (   ) |   ) (   | (    \/| )   ( || (   ) |( \   / )
// | |      | (___) |   | |   | (__    | | _ | || (___) | \ (_) /
// | | ____ |  ___  |   | |   |  __)   | |( )| ||  ___  |  \   /
// | | \_  )| (   ) |   | |   | (      | || || || (   ) |   ) (
// | (___) || )   ( |   | |   | (____/\| () () || )   ( |   | |
// (_______)|/     \|   )_(   (_______/(_______)|/     \|   \_/
//
const getGatewayByMAC = async (mac) => {
  let url = BASE_API_URL + API_VERSION + "gateway/?macAddress=" + mac;
  return await callAPI(url);
};

const getGatewayBrief = async (id) => {
  let url = BASE_API_URL + API_VERSION + "gateway/" + id + "/brief";
  return await callAPI(url, API_GET, null, null, true);
};

const getGatewayDetails = async (siteGUID = null) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "gateway" +
    (siteGUID !== null ? "/?siteGuid=" + siteGUID : "");
  if (siteGUID) return await callAPI(url);
  else return await _getWithIteration(url, 50);
};

const getGatewaysFullDetails = async (siteGUID = null) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "gateway/details" +
    (siteGUID !== null ? "/?siteGuid=" + siteGUID : "");
  if (siteGUID) return await callAPI(url);
  else return await _getWithIteration(url, 100);
};

const getSingleGatewayDetail = async (gatewayGuid = null) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "gateway" +
    (gatewayGuid !== null ? "/" + gatewayGuid : "");

  if (gatewayGuid) return await callAPI(url);
  else return await _getWithIteration(url, 50);
};

const addGateway = (gwinfo) => {
  return callAPI(BASE_API_URL + API_VERSION + "gateway", API_POST, gwinfo)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteGateway = (gwGUID, hard = false) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "gateway/" +
      gwGUID +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const updateGateway = (gwGUID, gwName, gwTelemetryInterval, sGUID) => {
  let par = {
    gatewayGuid: gwGUID,
    siteGuid: sGUID,
    name: gwName,
    telemetryPushInterval: gwTelemetryInterval,
  };
  return callAPI(BASE_API_URL + API_VERSION + "gateway", API_PUT, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const refreshVars = async (gatewayGuid = null) => {
  if (gatewayGuid !== null && gatewayGuid !== undefined) {
    let gateway = (await getSingleGatewayDetail(gatewayGuid)).data;

    if (gateway.status === "ON") {
      await sendMessageToGateway(gatewayGuid, {
        type: "gcmd",
        cmd: "refreshvars",
      });
    }
  }
};

const changeGatewaySite = async (gatewayGuid, newSiteGuid) => {
  let par = {
    gatewayGuid: gatewayGuid,
    destinationSiteGuid: newSiteGuid,
  };
  return await callAPI(
    BASE_API_URL + API_VERSION + "gateway/change-site",
    API_POST,
    par,
    null,
    true
  );
};

// Only Admin!
const getGatewaysFull = async (includeDeleted = true) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "gateway/full?includeDeleted=" +
    includeDeleted;

  return await _getWithIteration(url);
};

//  _______  _______  _______  _______  _______  _______ _________ _______  _______
// (  ____ )(  ___  )(  ____ )(  ___  )(       )(  ____ \\__   __/(  ____ \(  ____ )
// | (    )|| (   ) || (    )|| (   ) || () () || (    \/   ) (   | (    \/| (    )|
// | (____)|| (___) || (____)|| (___) || || || || (__       | |   | (__    | (____)|
// |  _____)|  ___  ||     __)|  ___  || |(_)| ||  __)      | |   |  __)   |     __)
// | (      | (   ) || (\ (   | (   ) || |   | || (         | |   | (      | (\ (
// | )      | )   ( || ) \ \__| )   ( || )   ( || (____/\   | |   | (____/\| ) \ \__
// |/       |/     \||/   \__/|/     \||/     \|(_______/   )_(   (_______/|/   \__/

const updateParameterMap = async (gatewayGuid = null, addresses = []) => {
  if (gatewayGuid !== null && gatewayGuid !== undefined) {
    let gateway = (await getSingleGatewayDetail(gatewayGuid)).data;

    let sAddr = [];

    for (let adr of addresses) {
      sAddr.push(adr.toString());
    }

    if (gateway.status === "ON") {
      await sendMessageToGateway(
        gatewayGuid,
        {
          type: "gcmd",
          cmd: "refresh_params",
          dvc: sAddr,
        },
        false
      );
    }
  }
};

const getAvailableParameterFiles = async (
  gatewayMAC = null,
  fileName = null
) => {
  if (gatewayMAC !== null && fileName !== null) {
    let deviceInfo = gatewayMAC + "/" + fileName;
    return await callAPIWithCancel(
      BASE_API_URL + API_VERSION + "device-map/list?nameFilter=" + deviceInfo,
      API_GET
    );
  }
};

const downloadMap = async (
  macAddress = null,
  device = null,
  changes = [],
  ftype = "bin"
) => {
  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    responseType: "blob",
  };

  return (
    await callAPI(
      BASE_API_URL +
        API_VERSION +
        "device-map/download?device=" +
        device +
        "&macAddress=" +
        macAddress +
        "&ftype=" +
        ftype,
      API_POST,
      createUpdateParFile(changes),
      config
    )
  ).data;
};

const uploadMap = async (deviceGuid = null, fileMap = null) => {
  if (!deviceGuid || !fileMap) return null;

  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    headers: { ...config.headers, "Content-Type": "multipart/form-data" },
  };

  let url =
    BASE_API_URL + API_VERSION + "device-map/upload?deviceGuid=" + deviceGuid;

  return callAPIWithCancel(url, API_POST, fileMap, config);
};

export const WX_FUNCTIONS = {
  READ: API_GET,
  EDIT: API_PUT,
  WRITE: API_POST,
};

const parameterMap = async (
  deviceGuid = null,
  func,
  parList = null,
  writeAll = false,
  resetNeeded = 1
) => {
  if (deviceGuid !== null) {
    let config = null;

    if (Object.values(WX_FUNCTIONS).indexOf(func) === -1) {
      config = await getAPICallAuthHeader();
      if (config === "logout") return null;
      config = {
        ...config,
        responseType: "blob",
      };
    }

    return await callAPIWithCancel(
      BASE_API_URL +
        API_VERSION +
        "device-map" +
        "?deviceGuid=" +
        deviceGuid +
        (func === WX_FUNCTIONS.WRITE
          ? "&resetNeeded=" + resetNeeded + "&writeAll=" + writeAll
          : ""),
      func,
      func === WX_FUNCTIONS.EDIT || func === WX_FUNCTIONS.WRITE
        ? createUpdateParFile(parList)
        : null,
      config,
      true
    );
  }
};

const createUpdateParFile = (parList) => {
  let updatePar = [];

  for (let par of parList) {
    updatePar.push({
      label: par.l,
      value: par.v,
      visibility: par.vis,
      editability: par.edi,
    });
  }

  return updatePar;
};

/* const writeParameters = async (gatewayGuid = null, address, parList) => {
  if (gatewayGuid !== null && gatewayGuid !== undefined && parList.length > 0) {
    let gateway = (await getSingleGatewayDetail(gatewayGuid)).data;
    if (gateway.status === "ON") {
      let updatePar = createUpdateParFile(parList);

      await sendMessageToGateway(gatewayGuid, {
        type: "gcmd",
        cmd: "write_params",
        dvc: [{ id: address, pars: updatePar }],
      });
    }
  }
}; */

const exportParametersList = async (deviceGuids = [], type = "pdf") => {
  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    responseType: "blob",
  };

  let url = BASE_API_URL + API_VERSION + "device-map/export?";

  for (let i = 0; i < deviceGuids.length; i++) {
    url += i > 0 ? "&" : "";
    url += "deviceGuids=" + deviceGuids[i];
  }

  url += "&ftype=" + type;

  return (await callAPI(url, API_POST, null, config)).data;
};

//  ______   _______          _________ _______  _______  _______
// (  __  \ (  ____ \|\     /|\__   __/(  ____ \(  ____ \(  ____ \
// | (  \  )| (    \/| )   ( |   ) (   | (    \/| (    \/| (    \/
// | |   ) || (__    | |   | |   | |   | |      | (__    | (_____
// | |   | ||  __)   ( (   ) )   | |   | |      |  __)   (_____  )
// | |   ) || (       \ \_/ /    | |   | |      | (            ) |
// | (__/  )| (____/\  \   /  ___) (___| (____/\| (____/\/\____) |
// (______/ (_______/   \_/   \_______/(_______/(_______/\_______)
//

const getDevicesDetails = async (gatewayGuid = null) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "device" +
    (gatewayGuid !== null ? "/?gatewayGuid=" + gatewayGuid : "");

  if (gatewayGuid) return await callAPI(url);
  else return await _getWithIteration(url);
};

const getDevice = async (deviceGuid = null) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "device" +
    (deviceGuid !== null ? "/" + deviceGuid : "");
  if (deviceGuid) return await callAPI(url);
  else return await _getWithIteration(url);
};

// Only Admin!
const getDevicesFull = async (includeDeleted = true) => {
  let url =
    BASE_API_URL + API_VERSION + "device/full?includeDeleted=" + includeDeleted;

  return await _getWithIteration(url);
};

const getDevicesOnGateway = async (gwGUID) => {
  var devices = [];

  const pl = (await getGatewayDetails()).data;
  const gateways = pl.data;
  const totalGateways = pl.total;

  if (totalGateways === 0) return { byId: {}, allIds: [] };
  for (const gw of gateways) {
    if (gw.gatewayGuid === gwGUID) {
      for (const dvc of gw.devices) {
        try {
          let dvcData = (await getDevice(dvc.deviceGuid)).data;

          if (dvcData.points === null) {
            dvcData.points = [];
          }

          for (let i = 0; i < dvcData.points.length; i++) {
            if (dvcData.points[i].lastValue === null)
              dvcData.points[i].lastValue = undefined;
          }

          dvcData.points = dvcData.points.filter((data) => {
            return data.hidden !== true;
          });

          let pointByIdObj = [];
          for (let point of dvcData.points) {
            pointByIdObj = {
              ...pointByIdObj,
              [point.code]: point,
            };
          }
          delete dvcData.points;
          dvcData["pointsById"] = pointByIdObj;

          devices.push(dvcData);
        } catch (ex) {
          utils.debug(
            "Invalid dvc or json files for this device: " + dvc.deviceGuid,
            utils.MSG_TYPE_WARN
          );
        }
      }
    }
  }

  var controllers = { byId: {}, allIds: [] };
  for (var c of devices) {
    controllers = {
      ...controllers,
      byId: { ...controllers.byId, [c.deviceGuid]: c },
    };
    controllers.allIds.push(c.deviceGuid);
  }
  return controllers;
};

const getDevicesOnSite = async (sGUID) => {
  var devices = [];

  const pl = (await getGatewayDetails(sGUID)).data;
  const gateways = pl.data;
  const totalGateways = pl.total;

  if (totalGateways === 0) return { byId: {}, allIds: [] };

  for (const gw of gateways) {
    if (gw.site.siteGuid === sGUID) {
      for (const dvc of gw.devices) {
        try {
          let dvcData = (await getDevice(dvc.deviceGuid)).data;

          if (dvcData.points === null) {
            dvcData.points = [];
          }

          for (let i = 0; i < dvcData.points.length; i++) {
            if (dvcData.points[i].lastValue === null)
              dvcData.points[i].lastValue = undefined;
          }

          dvcData.points = dvcData.points.filter((data) => {
            return data.hidden !== true;
          });

          let pointByIdObj = [];
          for (let point of dvcData.points) {
            pointByIdObj = {
              ...pointByIdObj,
              [point.code]: point,
            };
          }
          delete dvcData.points;
          dvcData["pointsById"] = pointByIdObj;

          devices.push(dvcData);
        } catch (ex) {
          utils.debug(
            "Invalid dvc or json files for this device: " + dvc.deviceGuid,
            utils.MSG_TYPE_WARN
          );
        }
      }
    }
  }

  var controllers = { byId: {}, allIds: [] };
  for (var c of devices) {
    controllers = {
      ...controllers,
      byId: { ...controllers.byId, [c.deviceGuid]: c },
    };
    controllers.allIds.push(c.deviceGuid);
  }
  return controllers;
};

const updateDevice = (dGuid, name, serialID) => {
  let param = {
    deviceGuid: dGuid,
    friendlyName: name,
    cabinetSerial: serialID,
  };

  return callAPI(BASE_API_URL + API_VERSION + "device", API_PUT, param)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const updateDeviceCustomizations = async (
  deviceGuid,
  customizationData = {}
) => {
  const url = `${BASE_API_URL}${API_VERSION}device/${deviceGuid}/customizations`;
  return await callAPI(url, API_PUT, customizationData);
};

//  _______  _______  _______  _______  _______  _______  _______ _________ _______  ______          _______  _______  _______  _______           _______  _______  _______
// (  ___  )(  ____ \(  ____ \(  ____ )(  ____ \(  ____ \(  ___  )\__   __/(  ____ \(  __  \        (       )(  ____ \(  ___  )(  ____ \|\     /|(  ____ )(  ____ \(  ____ \
// | (   ) || (    \/| (    \/| (    )|| (    \/| (    \/| (   ) |   ) (   | (    \/| (  \  )       | () () || (    \/| (   ) || (    \/| )   ( || (    )|| (    \/| (    \/
// | (___) || |      | |      | (____)|| (__    | |      | (___) |   | |   | (__    | |   ) | _____ | || || || (__    | (___) || (_____ | |   | || (____)|| (__    | (_____
// |  ___  || | ____ | | ____ |     __)|  __)   | | ____ |  ___  |   | |   |  __)   | |   | |(_____)| |(_)| ||  __)   |  ___  |(_____  )| |   | ||     __)|  __)   (_____  )
// | (   ) || | \_  )| | \_  )| (\ (   | (      | | \_  )| (   ) |   | |   | (      | |   ) |       | |   | || (      | (   ) |      ) || |   | || (\ (   | (            ) |
// | )   ( || (___) || (___) || ) \ \__| (____/\| (___) || )   ( |   | |   | (____/\| (__/  )       | )   ( || (____/\| )   ( |/\____) || (___) || ) \ \__| (____/\/\____) |
// |/     \|(_______)(_______)|/   \__/(_______/(_______)|/     \|   )_(   (_______/(______/        |/     \|(_______/|/     \|\_______)(_______)|/   \__/(_______/\_______)

const getAggregatedMeasuresByDate = async (
  deviceGuid = [],
  dateFrom = null,
  dateTo = new Date(),
  nMeasures = keys.MAX_MEASURE_INTERVAL_IN_DAYS * 24 * 4,
  minutes = 60
) => {
  let strTo;
  let strFrom;
  let strDevicesGuid = "";

  if (dateFrom === null) {
    strTo = utils.formatDate(dateTo).replace(/:/g, "%3A");
    strFrom = utils
      .formatDate(utils.setOffsetDate(dateTo, minutes))
      .replace(/:/g, "%3A");
  } else {
    strFrom = dateFrom;
    strTo = dateTo;
  }

  for (let i = 0; i < deviceGuid.length; i++) {
    strDevicesGuid = strDevicesGuid + "deviceGuids=" + deviceGuid[i] + "&";
  }

  let url =
    BASE_API_URL +
    API_VERSION +
    "aggregated-measures/by-datetime-range?" +
    strDevicesGuid +
    "from=" +
    strFrom +
    "&to=" +
    strTo +
    "&limit=" +
    nMeasures;

  return await callAPIWithCancel(url, API_GET, null, null, true);
};

const getAggregatedMeasuresByLastNValues = async (
  deviceGuid = [],
  nMeasures = 1
) => {
  let strDevicesGuid = "";

  for (let i = 0; i < deviceGuid.length; i++) {
    strDevicesGuid = strDevicesGuid + "deviceGuids=" + deviceGuid[i] + "&";
  }

  let url =
    BASE_API_URL +
    API_VERSION +
    "aggregated-measures/by-last-n-values?" +
    strDevicesGuid +
    "latestNValues=" +
    nMeasures;

  return await callAPIWithCancel(url); //return await callAPI(url);
};

//           _______  _     _________ _______  _______  _        _______  _        _______ __________________ _______  _               _______ _________ _        _______  _______
// |\     /|(  ____ \( \    \__   __/(  ____ )(  ___  )( (    /|(  ____ \( \      (  ___  )\__   __/\__   __/(  ___  )( (    /|       (  ____ \\__   __/( \      (  ____ \(  ____ \
// | )   ( || (    \/| (       ) (   | (    )|| (   ) ||  \  ( || (    \/| (      | (   ) |   ) (      ) (   | (   ) ||  \  ( |       | (    \/   ) (   | (      | (    \/| (    \/
// | | _ | || (__    | | _____ | |   | (____)|| (___) ||   \ | || (_____ | |      | (___) |   | |      | |   | |   | ||   \ | | _____ | (__       | |   | |      | (__    | (_____
// | |( )| ||  __)   | |(_____)| |   |     __)|  ___  || (\ \) |(_____  )| |      |  ___  |   | |      | |   | |   | || (\ \) |(_____)|  __)      | |   | |      |  __)   (_____  )
// | || || || (      | |       | |   | (\ (   | (   ) || | \   |      ) || |      | (   ) |   | |      | |   | |   | || | \   |       | (         | |   | |      | (            ) |
// | () () || (____/\| (____/\ | |   | ) \ \__| )   ( || )  \  |/\____) || (____/\| )   ( |   | |   ___) (___| (___) || )  \  |       | )      ___) (___| (____/\| (____/\/\____) |
// (_______)(_______/(_______/ )_(   |/   \__/|/     \||/    )_)\_______)(_______/|/     \|   )_(   \_______/(_______)|/    )_)       |/       \_______/(_______/(_______/\_______)
//

const getDeviceWelTranslationFiles = async (
  dpdGuid = null,
  mfve = null,
  limit = 50
) => {
  let url =
    BASE_API_URL + API_VERSION + "device-parameter-description-translations";
  let first = true;
  for (const par of [
    { name: "deviceParametersDescriptionGuid", val: dpdGuid },
    { name: "model", val: mfve },
  ]) {
    if (par.val !== null) {
      if (first === true) {
        first = false;
        url += "?" + par.name + "=" + par.val;
      } else url += "&" + par.name + "=" + par.val;
    }
  }

  if (dpdGuid) return await callAPI(url);
  else return await _getWithIteration(url, limit);
};

const getDeviceWelTranslationFileDetails = async (dpdGuid) => {
  return await callAPI(
    BASE_API_URL +
      API_VERSION +
      "device-parameter-description-translations/" +
      dpdGuid
  );
};

const addUpdateDeviceWelTranslationFile = async (mfve, file) => {
  const existingWelTFile = (await getDeviceWelTranslationFiles(null, mfve))
    .data;

  const model = utils.getModelFromMFVE(mfve);
  const fve_split = mfve.split("_")[1].split("-");

  const existingWelFile = (
    await getDeviceWelFiles(model, fve_split[0], fve_split[1], fve_split[2])
  ).data;

  if (existingWelFile.total === 0) return null;

  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    header: { ...config.header, "content-type": "multipart/form-data" },
  };
  let data = new FormData();
  data.append("formFile", file);
  if (existingWelTFile.total > 0) {
    // Update existing
    return callAPI(
      BASE_API_URL +
        API_VERSION +
        "device-parameter-description-translations?deviceParametersDescriptionTranslationsGuid=" +
        existingWelTFile.data[0].deviceParametersDescriptionTranslationsGuid +
        "&culture=en-GB",
      API_PUT,
      data,
      config
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  } else {
    // Create new
    return callAPI(
      BASE_API_URL +
        API_VERSION +
        "device-parameter-description-translations?deviceParametersDescriptionGuid=" +
        existingWelFile.data[0].deviceParametersDescriptionGuid +
        "&culture=en-GB",
      API_POST,
      data,
      config
    )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
};

const deleteDeviceWelTranslationFile = (dpdGuid, hard = false) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "device-parameter-description-translations/" +
      dpdGuid +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
//           _______  _         _______ _________ _        _______  _______
// |\     /|(  ____ \( \       (  ____ \\__   __/( \      (  ____ \(  ____ \
// | )   ( || (    \/| (       | (    \/   ) (   | (      | (    \/| (    \/
// | | _ | || (__    | | _____ | (__       | |   | |      | (__    | (_____
// | |( )| ||  __)   | |(_____)|  __)      | |   | |      |  __)   (_____  )
// | || || || (      | |       | (         | |   | |      | (            ) |
// | () () || (____/\| (____/\ | )      ___) (___| (____/\| (____/\/\____) |
// (_______)(_______/(_______/ |/       \_______/(_______/(_______/\_______)
//

const getDeviceWelFiles = async (
  model = null,
  family = null,
  version = null,
  memory = null,
  limit = 50
) => {
  let opt = "";
  let first = true;
  for (const par of [
    { name: "model", val: model },
    { name: "family", val: family },
    { name: "version", val: version },
    { name: "memory", val: memory },
  ]) {
    if (par.val !== null) {
      if (first === true) {
        first = false;
        opt += "?" + par.name + "=" + par.val;
      } else opt += "&" + par.name + "=" + par.val;
    }
  }

  return await _getWithIteration(
    BASE_API_URL + API_VERSION + "device-parameter-description" + opt,
    limit
  );
};

const getDeviceWelFileDetails = async (dpdGuid) => {
  return await callAPI(
    BASE_API_URL + API_VERSION + "device-parameter-description/" + dpdGuid
  );
};

const addUpdateDeviceWelFile = async (mfve, file) => {
  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    header: { ...config.header, "content-type": "multipart/form-data" },
  };
  let data = new FormData();
  data.append("formFile", file);
  return callAPI(
    BASE_API_URL + API_VERSION + "device-parameter-description?model=" + mfve,
    API_POST,
    data,
    config
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteDeviceWelFile = (dpdGuid, hard = false) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "device-parameter-description/" +
      dpdGuid +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//  _______  _______ _________ _______           _______                _______ _________ _        _______  _______
// (  ____ \(  ___  )\__   __/(  ____ \|\     /|(  ___  )|\     /|     (  ____ \\__   __/( \      (  ____ \(  ____ \
// | (    \/| (   ) |   ) (   | (    \/| )   ( || (   ) |( \   / )     | (    \/   ) (   | (      | (    \/| (    \/
// | |      | (___) |   | |   | (__    | | _ | || (___) | \ (_) /_____ | (__       | |   | |      | (__    | (_____
// | | ____ |  ___  |   | |   |  __)   | |( )| ||  ___  |  \   /(_____)|  __)      | |   | |      |  __)   (_____  )
// | | \_  )| (   ) |   | |   | (      | || || || (   ) |   ) (        | (         | |   | |      | (            ) |
// | (___) || )   ( |   | |   | (____/\| () () || )   ( |   | |        | )      ___) (___| (____/\| (____/\/\____) |
// (_______)|/     \|   )_(   (_______/(_______)|/     \|   \_/        |/       \_______/(_______/(_______/\_______)
//

const getDeviceRuntimeCommandFiles = async (
  model = null,
  family = null,
  version = null,
  memory = null,
  limit = 50
) => {
  let first = true;
  let url = BASE_API_URL + API_VERSION + "device-runtime-commands";
  for (const par of [
    { name: "model", val: model },
    { name: "family", val: family },
    { name: "version", val: version },
    { name: "memory", val: memory },
  ]) {
    if (par.val !== null) {
      if (first === true) {
        first = false;
        url += "?" + par.name + "=" + par.val;
      } else url += "&" + par.name + "=" + par.val;
    }
  }
  return await _getWithIteration(url, limit);
};

const getDeviceRuntimeCommandFileDetails = async (drcGuid) => {
  return await callAPI(
    BASE_API_URL + API_VERSION + "device-runtime-commands/" + drcGuid
  );
};

const addUpdateDeviceRuntimeCommandFile = async (mfve, file) => {
  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    header: { ...config.header, "content-type": "multipart/form-data" },
  };
  let data = new FormData();
  data.append("formFile", file);
  return callAPI(
    BASE_API_URL + API_VERSION + "device-runtime-commands?model=" + mfve,
    API_POST,
    data,
    config
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteDeviceRuntimeCommandFile = (drcGuid, hard = false) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "device-runtime-commands/" +
      drcGuid +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//  ______   _______          _________ _______  _______  _________          _______  _______      _______ _________ _        _______  _______
// (  __  \ (  ____ \|\     /|\__   __/(  ____ \(  ____ \ \__   __/|\     /|(  ____ )(  ____ \    (  ____ \\__   __/( \      (  ____ \(  ____ \
// | (  \  )| (    \/| )   ( |   ) (   | (    \/| (    \/    ) (   ( \   / )| (    )|| (    \/    | (    \/   ) (   | (      | (    \/| (    \/
// | |   ) || (__    | |   | |   | |   | |      | (__  _____ | |    \ (_) / | (____)|| (__  _____ | (__       | |   | |      | (__    | (_____
// | |   | ||  __)   ( (   ) )   | |   | |      |  __)(_____)| |     \   /  |  _____)|  __)(_____)|  __)      | |   | |      |  __)   (_____  )
// | |   ) || (       \ \_/ /    | |   | |      | (          | |      ) (   | (      | (          | (         | |   | |      | (            ) |
// | (__/  )| (____/\  \   /  ___) (___| (____/\| (____/\    | |      | |   | )      | (____/\    | )      ___) (___| (____/\| (____/\/\____) |
// (______/ (_______/   \_/   \_______/(_______/(_______/    )_(      \_/   |/       (_______/    |/       \_______/(_______/(_______/\_______)
//

const getDeviceTypeFiles = async (limit = 50) => {
  return await _getWithIteration(
    BASE_API_URL + API_VERSION + "device-type-configuration",
    limit
  );
};

const getDeviceTypeFileDetails = async (dtcGuid) => {
  return await callAPI(
    BASE_API_URL + API_VERSION + "device-type-configuration/" + dtcGuid
  );
};

const addUpdateDeviceTypeFile = async (mfve, file) => {
  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    header: { ...config.header, "content-type": "multipart/form-data" },
  };
  let data = new FormData();
  data.append("formFile", file);
  return callAPI(
    BASE_API_URL + API_VERSION + "device-type-configuration?model=" + mfve,
    API_POST,
    data,
    config
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteDeviceTypeFile = (dtcGuid, hard = false) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "device-type-configuration/" +
      dtcGuid +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const getDeviceDSCList = async () => {
  const [
    typeConfigurationsPromiseResult,
    runtimeCommandsPromiseResult,
    parametersDescriptionsPromiseResult,
    parametersDescriptionTranslationsPromiseResult,
  ] = await Promise.all([
    getDeviceTypeFiles(9000),
    getDeviceRuntimeCommandFiles(null, null, null, null, 9000),
    getDeviceWelFiles(null, null, null, null, 9000),
    getDeviceWelTranslationFiles(null, null, 9000),
  ]);

  const typeConfigurations = typeConfigurationsPromiseResult.data.data;
  const runtimeCommands = runtimeCommandsPromiseResult.data.data;
  const parametersDescriptions = parametersDescriptionsPromiseResult.data.data;
  const parametersDescriptionTranslations =
    parametersDescriptionTranslationsPromiseResult.data.data;

  let dscFilesList = [];
  for (const deviceTypeConfiguration of typeConfigurations) {
    const [model, fve] = deviceTypeConfiguration.model.split("_");
    const [family, version, eeprom] = fve ? fve.split("-") : [null, null, null];

    const deviceRuntimeCommands = runtimeCommands.find(
      (x) => x.model === deviceTypeConfiguration?.model
    );
    const deviceParametersDescription = parametersDescriptions.find(
      (x) => x.model === deviceTypeConfiguration?.model
    );
    const deviceParametersDescriptionTranslations =
      parametersDescriptionTranslations.find(
        (x) =>
          x.deviceParametersDescriptionGuid ===
          deviceParametersDescription?.deviceParametersDescriptionGuid
      );

    dscFilesList.push({
      guid: deviceTypeConfiguration.deviceTypeConfigurationGuid,
      model: model,
      family: family,
      version: version,
      e2: eeprom,
      wel: {
        state:
          deviceParametersDescription &&
          deviceParametersDescriptionTranslations,
        wGuid: deviceParametersDescription
          ? deviceParametersDescription.deviceParametersDescriptionGuid
          : null,
        tGuid: deviceParametersDescriptionTranslations
          ? deviceParametersDescriptionTranslations.deviceParametersDescriptionTranslationsGuid
          : null,
      },
      gwdsc: {
        state: !!deviceRuntimeCommands,
        guid: deviceRuntimeCommands
          ? deviceRuntimeCommands.deviceRuntimeCommandsGuid
          : null,
      },
    });
  }
  return dscFilesList;
};

const getGatewayInitialState = async (
  siteGuid,
  gatewaysList = null,
  fromDashboard = false
) => {
  let apigateways = utils.sortArrayOfObjects(
    gatewaysList
      ? gatewaysList
      : (await getGatewaysFullDetails(siteGuid)).data.data,
    "name"
  );
  let gateways = { byId: {}, allIds: [] };
  for (var g of apigateways) {
    g.currentDateTime = new Date(); // Added for consistency with data coming from the stream
    g.licenses = utils.setLicensesInfo(g.licenses);

    gateways = {
      ...gateways,
      byId: { ...gateways.byId, [g.gatewayGuid]: g },
    };
    gateways.allIds.push(g.gatewayGuid);
  }

  if (fromDashboard) {
    gateways = { ...gateways, fromDashboard: true };
  }

  return gateways;
};

// SITES
const getSiteInitialState = async (siteList = null, fromDashboard = false) => {
  let apisites = utils.sortArrayOfObjects(
    siteList ? siteList : (await getSites()).data.data,
    "name"
  );
  var sites = { byId: {}, allIds: [] };

  // These statements will be removed
  for (var site of apisites) {
    if (!site.connectivitytype) site.connectivitytype = "wifi";
    if (!site.latitude) site.latitude = 41.9109;
    if (!site.longitude) site.longitude = 12.4818;
    sites = {
      ...sites,
      byId: { ...sites.byId, [site.siteGuid]: site },
    };
    sites.allIds.push(site.siteGuid);
  }

  if (fromDashboard) {
    sites = { ...sites, fromDashboard: true };
  }

  return sites;
};

const sendMessageToGateway = async (guid, message, skipAlert = true) => {
  try {
    return await callAPI(
      BASE_API_URL + API_VERSION + "gateway/send-message",
      API_POST,
      { guid, message },
      null,
      skipAlert
    );
  } catch (error) {
    return error;
  }
};

// **********************************************************
const getAllThresholdDetails = async (
  deviceGuid = null,
  gatewayGuid = null
) => {
  let url = BASE_API_URL + API_VERSION + "threshold";
  let first = true;
  for (const par of [
    { name: "deviceGuid", val: deviceGuid },
    { name: "gatewayGuid", val: gatewayGuid },
  ]) {
    if (par.val !== null) {
      if (first === true) {
        first = false;
        url += "?" + par.name + "=" + par.val;
      } else url += "&" + par.name + "=" + par.val;
    }
  }
  return await _getWithIteration(url, 100);
};

// arr is an array of objects like the following
//   deviceGuid,
//   label,
//   fieldName,
//   maxValue,
//   minValue,
//   rangePercentage

const createNewThresholds = (arr) => {
  let par = arr;
  return callAPI(BASE_API_URL + API_VERSION + "threshold", API_POST, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const setDefaultThresholds = (deviceGuid) => {
  return callAPI(
    BASE_API_URL + API_VERSION + `device/${deviceGuid}/default-thresholds`,
    API_POST
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// {
//   deviceGuid: deviceGuid,
//   label: label,
//   fieldName: fName,
//   maxValue: maxValue,
//   minValue: minValue,
//   rangePercentage: rangePercentage,
// }
const editExistingThreshold = (thObject) => {
  return callAPI(BASE_API_URL + API_VERSION + "threshold", API_PUT, thObject)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const getAllThresholdDetailsFull = async (
  deviceGuid = null,
  gatewayGuid = null,
  includeDeleted = false
) => {
  let url = BASE_API_URL + API_VERSION + "threshold/full";
  let first = true;
  for (const par of [
    { name: "deviceGuid", val: deviceGuid },
    { name: "gatewayGuid", val: gatewayGuid },
    { name: "includeDeleted", val: includeDeleted ? "true" : "false" },
  ]) {
    if (par.val !== null) {
      if (first === true) {
        first = false;
        url += "?" + par.name + "=" + par.val;
      } else url += "&" + par.name + "=" + par.val;
    }
  }
  return await _getWithIteration(url, 100);
};

const getThresholdDetailsByGuid = async (thresholdGuid) => {
  return await callAPI(
    BASE_API_URL + API_VERSION + "threshold/" + thresholdGuid
  );
};

const deleteThreshold = (thresholdGuid, hard = true) => {
  // Remove alert from redux store if active
  reduxStore.dispatch(removeAlert(thresholdGuid));

  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "threshold/" +
      thresholdGuid +
      (hard ? "?hardDelete=true" : ""),
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// #################################################
const getAllThresholdNotify = async (thresholdGuid = null) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "threshold-email-notify" +
    (thresholdGuid !== null ? "?thresholdGuid=" + thresholdGuid : "");
  if (thresholdGuid) return await callAPI(url);
  else return await _getWithIteration(url, 100);
};

// arr is an array of objects like the following:
// enableNotification: "ON" or "OFF",
// email: p.email,
// thresholdGuid: p.thresholdGuid,
const createOrUpdateThresholdNotify = (arr) => {
  let par = arr;
  return callAPI(
    BASE_API_URL + API_VERSION + "threshold-email-notify/list",
    API_POST,
    par
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteThresholdNotify = (thresholdGuid, email) => {
  return callAPI(
    BASE_API_URL +
      API_VERSION +
      "threshold-email-notify?thresholdGuid=" +
      thresholdGuid +
      "&email=" +
      email,
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const deleteAllThresholdNotifyForThreshold = (thresholdGuid) => {
  return callAPI(
    BASE_API_URL + API_VERSION + "threshold-email-notify/" + thresholdGuid,
    API_DELETE
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

//  _______  _        _______  _______ _________ _______    _        _______  _______
// (  ___  )( \      (  ____ \(  ____ )\__   __/(  ____ \  ( \      (  ___  )(  ____ \
// | (   ) || (      | (    \/| (    )|   ) (   | (    \/  | (      | (   ) || (    \/
// | (___) || |      | (__    | (____)|   | |   | (_____   | |      | |   | || |
// |  ___  || |      |  __)   |     __)   | |   (_____  )  | |      | |   | || | ____
// | (   ) || |      | (      | (\ (      | |         ) |  | |      | |   | || | \_  )
// | )   ( || (____/\| (____/\| ) \ \__   | |   /\____) |  | (____/\| (___) || (___) |
// |/     \|(_______/(_______/|/   \__/   )_(   \_______)  (_______/(_______)(_______)

const getAlertsLog = async (
  gatewayGuid,
  deviceGuid,
  thresholdGuid,
  includeDeleted = false
) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "alerts-log?" +
    (gatewayGuid !== undefined ? "gatewayGuid=" + gatewayGuid + "&" : "") +
    (deviceGuid !== undefined ? "deviceGuid=" + deviceGuid + "&" : "") +
    (thresholdGuid !== undefined
      ? "thresholdGuid=" + thresholdGuid + "&"
      : "") +
    "includeDeleted=" +
    includeDeleted;

  return await _getWithIteration(url, 500);
};

const getAlertsLogByDeviceGuidsAndTimeSpan = async (
  deviceGuids = [],
  from,
  to,
  includeDeleted = false
) => {
  let url = BASE_API_URL + API_VERSION + "alerts-log/filter?";

  if (deviceGuids.length > 0) {
    let i = 0;

    for (let deviceGuid of deviceGuids) {
      url += (i > 0 ? "&" : "") + "deviceGuids=" + deviceGuid;
      i++;
    }

    url += "&";
  }

  let strFrom = utils.formatDate(from).replace(/:/g, "%3A");
  let strTo = utils.formatDate(to).replace(/:/g, "%3A");

  url +=
    "from=" + strFrom + "&to=" + strTo + "&includeDeleted=" + includeDeleted;

  return await _getWithIteration(url, 100);
};

const getAlertStatus = async (
  deviceGuid = null,
  gatewayGuid = null,
  thresholdGuid = null,
  status = null
) => {
  let url = BASE_API_URL + API_VERSION + "alerts-status";
  let first = true;
  for (const par of [
    { name: "deviceGuid", val: deviceGuid },
    { name: "gatewayGuid", val: gatewayGuid },
    { name: "thresholdGuid", val: thresholdGuid },
    { name: "status", val: status },
  ]) {
    if (par.val !== null) {
      if (first === true) {
        first = false;
        url += "?" + par.name + "=" + par.val;
      } else url += "&" + par.name + "=" + par.val;
    }
  }
  return await _getWithIteration(url, 100);
};

const getAlertInitialState = async () => {
  let apiAlerts = (await getAlertStatus()).data.data;
  let alerts = { byId: {}, allIds: [], total: 0 };
  let newTotal = 0;
  for (const alert of apiAlerts) {
    let a = alert.threshold;
    const gatewayGuid = a.gatewayGuid;
    const deviceGuid = a.deviceGuid;
    a["alarmType"] = a.alertStatus;
    delete a.alertStatus;
    if (a.alarmType.toUpperCase() === "ON" && a.deletedAt === null) {
      let newThresholds = alerts.byId[gatewayGuid]
        ? alerts.byId[gatewayGuid][deviceGuid] || []
        : [];
      newTotal += 1;
      if (alerts.byId[gatewayGuid]) {
        /*
         * Gateway already present on allIds
         * There is no need to update allIds
         */
        newThresholds.push(a);
        alerts = {
          ...alerts,
          total: newTotal,
          byId: {
            ...alerts.byId,
            [gatewayGuid]: {
              ...alerts.byId[gatewayGuid],
              [deviceGuid]: newThresholds,
            },
          },
        };
      } else {
        /* Gateway not already present on the list */
        let newAllIds = alerts.allIds;
        newAllIds.push(gatewayGuid);
        newThresholds.push(a);
        alerts = {
          ...alerts,
          total: newTotal,
          byId: {
            ...alerts.byId,
            [gatewayGuid]: {
              ...alerts.byId[gatewayGuid],
              [deviceGuid]: newThresholds,
            },
          },
          allIds: newAllIds,
        };
      }
    } else {
      let newThresholds = alerts.byId[gatewayGuid]
        ? alerts.byId[gatewayGuid][deviceGuid] || []
        : [];
      let newAllIds = alert.allIds;

      let idx = -1;
      for (const id in newThresholds) {
        if (newThresholds[id].thresholdGuid === a.thresholdGuid) {
          idx = id;
          break;
        }
      }
      if (idx > -1) {
        newThresholds.splice(idx);
        if (newThresholds.length === 0) {
          let idx = newAllIds.indexOf(gatewayGuid);
          if (idx > -1) newAllIds.splice(idx);
          delete alerts.byId[gatewayGuid];
        }
        newTotal -= 1;
        if (newTotal < 0) newTotal = 0;
        alerts = {
          ...alerts,
          total: newTotal,
          byId: {
            ...alerts.byId,
            [gatewayGuid]: {
              ...alerts.byId[gatewayGuid],
              [deviceGuid]: newThresholds,
            },
          },
          allIds: newAllIds,
        };
      } else alerts = { ...alerts, total: newTotal };
    }
  }
  return alerts;
};

const getLatestAlertLogByThresholdGuidAsync = async (
  thresholdGuid,
  alertEventType = 2
) => {
  const url = `${BASE_API_URL}${API_VERSION}alerts-log/latest/${thresholdGuid}?alertEventType=${alertEventType}`;
  let response = await callAPI(url);

  if (response.status === 200) {
    return response.data;
  }
  throw new Error("Error fetching latest alert log");
};

//  _______  _______  _______  _______ _________ _______  _______ _________ _______  _        _______
// (  ____ )(  ____ \(  ____ )(       )\__   __/(  ____ \(  ____ \\__   __/(  ___  )( (    /|(  ____ \
// | (    )|| (    \/| (    )|| () () |   ) (   | (    \/| (    \/   ) (   | (   ) ||  \  ( || (    \/
// | (____)|| (__    | (____)|| || || |   | |   | (_____ | (_____    | |   | |   | ||   \ | || (_____
// |  _____)|  __)   |     __)| |(_)| |   | |   (_____  )(_____  )   | |   | |   | || (\ \) |(_____  )
// | (      | (      | (\ (   | |   | |   | |         ) |      ) |   | |   | |   | || | \   |      ) |
// | )      | (____/\| ) \ \__| )   ( |___) (___/\____) |/\____) |___) (___| (___) || )  \  |/\____) |
// |/       (_______/|/   \__/|/     \|\_______/\_______)\_______)\_______/(_______)|/    )_)\_______)

const createNewSubscriptionAdminOnly = async (ownerEmail, subscriptionName) => {
  let par = { email: ownerEmail, customerName: subscriptionName };
  return callAPI(BASE_API_URL + API_VERSION + "user/sub", API_POST, par)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

const getGatewayTwin = async (gatewayGuid) => {
  try {
    if (gatewayGuid) {
      let url =
        BASE_API_URL +
        API_VERSION +
        "gateway/gateway-twin?gatewayGuid=" +
        gatewayGuid;
      return await callAPI(url, API_GET, null, null, true);
    }
  } catch (e) {}

  return null;
};

// ______   _______          _________ _______  _______    ______   _______ _________ _______
// (  __  \ (  ____ \|\     /|\__   __/(  ____ \(  ____ \  (  __  \ (  ___  )\__   __/(  ___  )
// | (  \  )| (    \/| )   ( |   ) (   | (    \/| (    \/  | (  \  )| (   ) |   ) (   | (   ) |
// | |   ) || (__    | |   | |   | |   | |      | (__      | |   ) || (___) |   | |   | (___) |
// | |   | ||  __)   ( (   ) )   | |   | |      |  __)     | |   | ||  ___  |   | |   |  ___  |
// | |   ) || (       \ \_/ /    | |   | |      | (        | |   ) || (   ) |   | |   | (   ) |
// | (__/  )| (____/\  \   /  ___) (___| (____/\| (____/\  | (__/  )| )   ( |   | |   | )   ( |
// (______/ (_______/   \_/   \_______/(_______/(_______/  (______/ |/     \|   )_(   |/     \|

/**
 *
 * @param {*} devices
 * @param {*} siteGuid
 * @param {*} from
 * @param {*} to
 * @param {*} output
 * @returns
 */
const getDeviceMeasure = async (
  devices = [],
  siteGuid = null,
  from = null,
  to = null,
  output = "xls"
) => {
  if (devices.length === 0) return null;
  let url = BASE_API_URL + API_VERSION + "device-data?";

  let i = 0;
  for (let device of devices) {
    url = url + (i === 0 ? "" : "&") + "devices=" + device;
    i++;
  }

  if (siteGuid) url += "&siteGuid=" + siteGuid;

  let strFrom = utils.formatDate(from).replace(/:/g, "%3A");
  let strTo = utils.formatDate(to).replace(/:/g, "%3A");

  url = url + "&from=" + strFrom;
  url = url + "&to=" + strTo;

  if (output !== "xls" && output !== "zip") url = url + "&output=xls";
  else url = url + "&output=" + output;

  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    responseType: "blob",
  };

  return await callAPIWithCancel(url, API_GET, null, config, true);
};

/**
 *
 * @param {*} siteGuid
 * @param {*} date
 * @param {*} highAlarm
 * @param {*} highWarning
 * @param {*} lowWarning
 * @param {*} lowAlarm
 * @param {*} output
 * @returns
 */
const getDeviceReport = async (
  siteGuid,
  date = null,
  highAlarm,
  highWarning,
  lowWarning,
  lowAlarm,
  foodQuality = 0,
  output = "pdf"
) => {
  if (siteGuid === null || siteGuid === "") return null;

  let url = BASE_API_URL + API_VERSION + "device-data?siteGuid=" + siteGuid;

  if (date !== null) url = url + "&date=" + date;

  if (
    foodQuality === 1 &&
    highAlarm !== null &&
    highWarning !== null &&
    lowWarning !== null &&
    lowAlarm !== null
  ) {
    url =
      url +
      "&highAlarm=" +
      highAlarm +
      "&highWarning=" +
      highWarning +
      "&lowAlarm=" +
      lowAlarm +
      "&lowWarning=" +
      lowWarning;
  }

  url = url + "&foodQualityReport=" + (foodQuality === 0 ? false : true);

  if (output !== "pdf" && output !== "xls") url = url + "&output=pdf";
  else url = url + "&output=" + output;

  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    responseType: "blob",
  };

  return await callAPIWithCancel(url, API_POST, null, config, true);
};

// _______  _______  _______  _       _________ _______  _        ______    _________ _______  _______  _        _______  _        _______ __________________ _______  _
// (  ____ \(  ____ )(  ___  )( (    /|\__   __/(  ____ \( (    /|(  __  \   \__   __/(  ____ )(  ___  )( (    /|(  ____ \( \      (  ___  )\__   __/\__   __/(  ___  )( (    /|
// | (    \/| (    )|| (   ) ||  \  ( |   ) (   | (    \/|  \  ( || (  \  )     ) (   | (    )|| (   ) ||  \  ( || (    \/| (      | (   ) |   ) (      ) (   | (   ) ||  \  ( |
// | (__    | (____)|| |   | ||   \ | |   | |   | (__    |   \ | || |   ) |     | |   | (____)|| (___) ||   \ | || (_____ | |      | (___) |   | |      | |   | |   | ||   \ | |
// |  __)   |     __)| |   | || (\ \) |   | |   |  __)   | (\ \) || |   | |     | |   |     __)|  ___  || (\ \) |(_____  )| |      |  ___  |   | |      | |   | |   | || (\ \) |
// | (      | (\ (   | |   | || | \   |   | |   | (      | | \   || |   ) |     | |   | (\ (   | (   ) || | \   |      ) || |      | (   ) |   | |      | |   | |   | || | \   |
// | )      | ) \ \__| (___) || )  \  |   | |   | (____/\| )  \  || (__/  )     | |   | ) \ \__| )   ( || )  \  |/\____) || (____/\| )   ( |   | |   ___) (___| (___) || )  \  |
// |/       |/   \__/(_______)|/    )_)   )_(   (_______/|/    )_)(______/      )_(   |/   \__/|/     \||/    )_)\_______)(_______/|/     \|   )_(   \_______/(_______)|/    )_)

/**
 * Get Languages List
 *
 * @return {*}
 */
const getLanguagesList = async () => {
  let url = BASE_API_URL + API_VERSION + "translations/list";

  return await callAPI(url, API_GET);
};

/**
 * Get Translation file
 *
 * @param {*} language
 * @return {*}
 */
const getTranslations = async (language) => {
  let url = BASE_API_URL + API_VERSION + "translations?language=" + language;

  return await callAPI(url, API_GET);
};

//           _______  _______  _______    _______ __________________ _______ _________ ______           _________ _______
// |\     /|(  ____ \(  ____ \(  ____ )  (  ___  )\__   __/\__   __/(  ____ )\__   __/(  ___ \ |\     /|\__   __/(  ____ \
// | )   ( || (    \/| (    \/| (    )|  | (   ) |   ) (      ) (   | (    )|   ) (   | (   ) )| )   ( |   ) (   | (    \/
// | |   | || (_____ | (__    | (____)|  | (___) |   | |      | |   | (____)|   | |   | (__/ / | |   | |   | |   | (__
// | |   | |(_____  )|  __)   |     __)  |  ___  |   | |      | |   |     __)   | |   |  __ (  | |   | |   | |   |  __)
// | |   | |      ) || (      | (\ (     | (   ) |   | |      | |   | (\ (      | |   | (  \ \ | |   | |   | |   | (
// | (___) |/\____) || (____/\| ) \ \__  | )   ( |   | |      | |   | ) \ \_____) (___| )___) )| (___) |   | |   | (____/\
// (_______)\_______)(_______/|/   \__/  |/     \|   )_(      )_(   |/   \__/\_______/|/ \___/ (_______)   )_(   (_______/

const editUserAttribute = async (userGuid, key, value) => {
  let url = BASE_API_URL + API_VERSION + "user-attribute";

  let par = { userGuid: userGuid, key: key, value: value };

  return await callAPI(url, API_POST, par);
};

//  _       _________ _______  _______  _        _______  _______
// ( \      \__   __/(  ____ \(  ____ \( (    /|(  ____ \(  ____ \
// | (         ) (   | (    \/| (    \/|  \  ( || (    \/| (    \/
// | |         | |   | |      | (__    |   \ | || (_____ | (__
// | |         | |   | |      |  __)   | (\ \) |(_____  )|  __)
// | |         | |   | |      | (      | | \   |      ) || (
// | (____/\___) (___| (____/\| (____/\| )  \  |/\____) || (____/\
// (_______/\_______/(_______/(_______/|/    )_)\_______)(_______/

///////////////////////////////////////
// A C T I V A T I O N   C O D E
///////////////////////////////////////

// Admin only
const getActivationCodes = async () => {
  let url = BASE_API_URL + API_VERSION + "activation-code?includeExpired=true";

  return await _getWithIteration(url);
};

// Admin only
const createActivationCode = async (soldFor, salesNote = null) => {
  return await callAPI(
    BASE_API_URL +
      API_VERSION +
      "activation-code?soldFor=" +
      soldFor +
      (salesNote ? "&salesNote=" + salesNote.replace(/\s/g, "%20") : ""),
    API_POST
  );
};

// Admin or user
const disableActivationCode = async (guid) => {
  if (guid === null) return null;

  let editPar = {
    uploadedAt: new Date().toISOString(),
    activationCodeGuid: guid,
  };

  let url = BASE_API_URL + API_VERSION + "activation-code";

  return await callAPI(url, API_PUT, editPar);
};

// Admin or user
const editActivationCode = async (guid, salesNote, soldFor) => {
  if (guid === null) return null;

  let editPar = {
    salesNote: salesNote,
    soldFor: soldFor,
    activationCodeGuid: guid,
  };

  let url = BASE_API_URL + API_VERSION + "activation-code";

  return await callAPI(url, API_PUT, editPar);
};

// Admin only
const deleteActivationCode = async (guid) => {
  let url = BASE_API_URL + API_VERSION + "activation-code/" + guid;

  return await callAPI(url, API_DELETE);
};

///////////////////////////////////////
// L I C E N S E
///////////////////////////////////////

const createLicense = async (
  activationCode = null,
  duration = null,
  licenseName = null,
  licenseFile = null
) => {
  if (!activationCode || !duration || (!licenseName && !licenseFile)) {
    return null;
  }

  let url =
    BASE_API_URL +
    API_VERSION +
    "license?activationCodeGuid=" +
    activationCode +
    "&duration=" +
    duration;

  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    headers: { ...config.headers, "Content-Type": "multipart/form-data" },
  };

  if (!licenseName) {
    return await callAPI(url, API_POST, licenseFile, config);
  } else {
    url += "&licenseName=" + licenseName;

    return await callAPI(url, API_POST, null, config);
  }
};

const editLicense = async (
  licenseGuid,
  gatewayGuid,
  activeFrom = null,
  isActive = undefined,
  notification = undefined
) => {
  let url =
    BASE_API_URL +
    API_VERSION +
    "license?licenseGuid=" +
    licenseGuid +
    "&gatewayGuid=" +
    gatewayGuid +
    (activeFrom ? "&activeFrom=" + activeFrom.toISOString() : "") +
    (isActive !== undefined ? "&isActive=" + isActive || 0 : "") +
    // only for admin!
    (utils.isUserAdmin() && notification !== undefined
      ? "&notification=" + (notification || 0)
      : "");

  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    headers: { ...config.headers, "Content-Type": "multipart/form-data" },
  };

  return await callAPI(url, API_PUT, null, config);
};

const getAvailableLicenses = async () => {
  let url = BASE_API_URL + API_VERSION + "license/list";

  return await callAPI(url);
};

const getThirdPartyDevices = async () => {
  let url = `${BASE_API_URL + API_VERSION}third-party-devices`;
  let config = await getAPICallAuthHeader();
  if (config === "logout") return [];
  config = {
    ...config,
    headers: {
      ...config.headers,
      accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return await callAPI(url, API_GET, null, config);
};

///////////////////////////////////////
// B R I D G E
///////////////////////////////////////

const getLicensesByListOfSubscriptionGuids = async (subscriptionGuids = []) => {
  let url = BASE_API_URL + API_VERSION + "bridge";

  let i = 0;
  for (let guid of subscriptionGuids) {
    url += (i === 0 ? "?" : "&") + "subscriptionGuids=" + guid;
    i++;
  }

  return await _getWithIteration(url);
};

const addLicenseToSubscription = async (
  subscriptionGuids = [],
  licenseGuids = []
) => {
  if (subscriptionGuids.length === 0 || licenseGuids.length === 0) {
    return false;
  }

  let okResponses = 0;

  for (let licenseGuid of licenseGuids) {
    for (let subscriptionGuid of subscriptionGuids) {
      let url =
        BASE_API_URL +
        API_VERSION +
        "bridge?subscriptionGuid=" +
        subscriptionGuid +
        "&licenseGuid=" +
        licenseGuid;
      let response = await callAPI(url, API_POST);
      if (response.status === 201) okResponses++;
    }
  }

  return okResponses === licenseGuids.length * subscriptionGuids.length;
};

//  _        _______ _________ _______
// | \    /\(  ____ )\__   __/(  ____ \
// |  \  / /| (    )|   ) (   | (    \/
// |  (_/ / | (____)|   | |   | (_____
// |   _ (  |  _____)   | |   (_____  )
// |  ( \ \ | (         | |         ) |
// |  /  \ \| )      ___) (___/\____) |
// |_/    \/|/       \_______/\_______)

const getKpi = async (id, message) => {
  let url = `${BASE_API_URL + API_VERSION}device-data/kpi/${id}`;

  let config = await getAPICallAuthHeader();
  if (config === "logout") return null;
  config = {
    ...config,
    headers: {
      ...config.headers,
      accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  return await callAPIWithCancel(
    url,
    API_POST,
    JSON.stringify(message),
    config
  );
};

//  ______   _______  _______           ______   _______  _______  _______  ______
// (  __  \ (  ___  )(  ____ \|\     /|(  ___ \ (  ___  )(  ___  )(  ____ )(  __  \
// | (  \  )| (   ) || (    \/| )   ( || (   ) )| (   ) || (   ) || (    )|| (  \  )
// | |   ) || (___) || (_____ | (___) || (__/ / | |   | || (___) || (____)|| |   ) |
// | |   | ||  ___  |(_____  )|  ___  ||  __ (  | |   | ||  ___  ||     __)| |   | |
// | |   ) || (   ) |      ) || (   ) || (  \ \ | |   | || (   ) || (\ (   | |   ) |
// | (__/  )| )   ( |/\____) || )   ( || )___) )| (___) || )   ( || ) \ \__| (__/  )
// (______/ |/     \|\_______)|/     \||/ \___/ (_______)|/     \||/   \__/(______/

const getDashboard = async () => {
  let url = `${BASE_API_URL + API_VERSION}dashboard`;

  return await _getWithIteration(url, 100);
};

///////////////////////////////////////
// R E P O R T
///////////////////////////////////////

const getSiteAutomaticReport = async (siteGuid) => {
  return await callAPI(
    BASE_API_URL + API_VERSION + `site/${siteGuid}/automatic-report-config`,
    API_GET,
    null,
    null,
    true
  );
};

const upsertSiteAutomaticReport = ({
  siteGuid,
  time,
  reportType,
  emailList,
  isEnabled,
  fqrSettings,
  instantSend = false,
}) => {
  let par = {
    time,
    reportType,
    emailList,
    isEnabled,
    fqrSettings,
    instantSend,
  };
  return callAPI(
    BASE_API_URL + API_VERSION + `site/${siteGuid}/automatic-report-config`,
    API_PUT,
    par,
    null,
    true
  );
};

const backendService = {
  callMappAPI,
  getAPICallAuthHeader,
  getCustomersDetails,
  getCustomersInitialState,
  addCustomer,
  deleteCustomer,
  updateCustomer,
  getCustomersFull,
  getSiteDetails,
  getSites,
  addSite,
  deleteSite,
  updateSite,
  getUsersDetails,
  addUsers,
  deleteUser,
  getUserRole,
  deleteUserRole,
  assignRoleToUser,
  updateUserRole,
  getGatewayBrief,
  getGatewayByMAC,
  getGatewayDetails,
  getSingleGatewayDetail,
  addGateway,
  deleteGateway,
  updateGateway,
  refreshVars,
  changeGatewaySite,
  getGatewaysFull,
  updateParameterMap,
  getAvailableParameterFiles,
  parameterMap,
  createUpdateParFile,
  downloadMap,
  uploadMap,
  exportParametersList,
  getDevicesDetails,
  getDevice,
  getDevicesOnGateway,
  getDevicesOnSite,
  getAggregatedMeasuresByLastNValues,
  getAggregatedMeasuresByDate,
  getDeviceWelTranslationFiles,
  getDeviceWelTranslationFileDetails,
  addUpdateDeviceWelTranslationFile,
  deleteDeviceWelTranslationFile,
  getDeviceWelFiles,
  getDeviceWelFileDetails,
  addUpdateDeviceWelFile,
  deleteDeviceWelFile,
  getDeviceRuntimeCommandFiles,
  getDeviceRuntimeCommandFileDetails,
  addUpdateDeviceRuntimeCommandFile,
  deleteDeviceRuntimeCommandFile,
  getDeviceTypeFiles,
  getDeviceTypeFileDetails,
  addUpdateDeviceTypeFile,
  deleteDeviceTypeFile,
  getDeviceDSCList,
  getGatewayInitialState,
  getSiteInitialState,
  sendMessageToGateway,
  getAllThresholdDetails,
  setDefaultThresholds,
  createNewThresholds,
  editExistingThreshold,
  getAllThresholdDetailsFull,
  getThresholdDetailsByGuid,
  deleteThreshold,
  getAllThresholdNotify,
  createOrUpdateThresholdNotify,
  deleteThresholdNotify,
  deleteAllThresholdNotifyForThreshold,
  getAlertsLog,
  getAlertsLogByDeviceGuidsAndTimeSpan,
  getAlertStatus,
  getAlertInitialState,
  getLatestAlertLogByThresholdGuidAsync,
  updateDevice,
  updateDeviceCustomizations,
  createNewSubscriptionAdminOnly,
  getDevicesFull,
  getGatewayTwin,
  getDeviceMeasure,
  getDeviceReport,
  getLanguagesList,
  getTranslations,
  editUserAttribute,
  getActivationCodes,
  disableActivationCode,
  editActivationCode,
  deleteActivationCode,
  createActivationCode,
  createLicense,
  editLicense,
  getAvailableLicenses,
  getThirdPartyDevices,
  getLicensesByListOfSubscriptionGuids,
  addLicenseToSubscription,
  getKpi,
  getDashboard,
  getSiteAutomaticReport,
  upsertSiteAutomaticReport,
};

export default backendService;

const callAPI = async (
  url,
  callType = API_GET,
  par = null,
  config = null,
  skipAlert = false
) => {
  let conf = null;
  let i = 0;
  if (config !== null) conf = config;
  else {
    while (conf === null && i < 10) {
      conf = await getAPICallAuthHeader();
      if (conf === "logout") return;
      i++;
    }
  }
  conf.validateStatus = false;

  return new Promise(async (resolve, reject) => {
    try {
      let res = null;
      switch (callType) {
        case API_GET:
          res = await axios.get(url, conf);
          break;
        case API_POST:
          res = await axios.post(url, par, conf);
          break;
        case API_PUT:
          res = await axios.put(url, par, conf);
          break;
        case API_DELETE:
          res = await axios.delete(url, conf);
          break;
        default:
          let error = {
            data: "Invalid function type",
            status: 404,
          };
          reject(error);
      }
      if (res.status >= 300) {
        if (!skipAlert) {
          notifyStore.addNotification({
            ...tempOptions,
            content: NotifyContent(
              "danger",
              null,
              res.data + " - " + res.status
            ),
          });
        }
        reject(res);
      } else {
        resolve(res);
      }
    } catch (error) {
      if (!skipAlert) {
        notifyStore.addNotification({
          ...tempOptions,
          content: NotifyContent(
            "danger",
            null,
            error.response === undefined
              ? error.message
              : error.response.statusText
          ),
        });
      }
      reject(error);
    }
  });
};

export let sources = {};

const callAPIWithCancel = async (
  url,
  callType = API_GET,
  par = null,
  config = null,
  skipAlert = false
) => {
  let conf = null;
  let i = 0;
  if (config !== null) conf = config;
  else {
    while (conf === null && i < 10) {
      conf = await getAPICallAuthHeader();
      if (conf === "logout") return;
      i++;
    }
  }

  let source = axios.CancelToken.source();
  conf.cancelToken = source.token;
  conf.validateStatus = false;

  let id = Math.max(Object.keys(sources)) + 1 || 1;

  sources[id] = { source: source };

  return {
    execute: new Promise(async (resolve, reject) => {
      try {
        let res = null;
        switch (callType) {
          case API_GET:
            res = await axios.get(url, conf);
            break;
          case API_POST:
            res = await axios.post(url, par, conf);
            break;
          case API_PUT:
            res = await axios.put(url, par, conf);
            break;
          case API_DELETE:
            res = await axios.delete(url, conf);
            break;
          default:
            let error = {
              data: "Invalid function type",
              status: 404,
            };
            reject(error);
        }
        if (res.status >= 300) {
          if (!skipAlert) {
            notifyStore.addNotification({
              ...tempOptions,
              content: NotifyContent(
                "danger",
                null,
                res.data + " - " + res.status
              ),
            });
          }
          reject(res);
        } else {
          resolve(res);
        }
      } catch (error) {
        if (error.toString() !== "Cancel" && !skipAlert) {
          notifyStore.addNotification({
            ...tempOptions,
            content: NotifyContent(
              "danger",
              null,
              error.response === undefined
                ? error.message
                : error.response.statusText
            ),
          });
        }
        reject(error);
      }
      delete sources[id];
    }),
    id: id,
  };
};
