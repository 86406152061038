export const UPDATE_SIGNALR_STATE = "UPDATE_SIGNALR_STATE";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_SITES = "SET_SITES";
export const RESET_SITES = "RESET_SITES";
export const SITES_LOADED = "SITES_LOADED";
export const SITE_UPDATE = "SITE_UPDATE";
export const SET_GATEWAYS = "SET_GATEWAYS";
export const RESET_GATEWAYS = "RESET_GATEWAYS";
export const GATEWAY_UPDATE = "GATEWAY_UPDATE";
export const SET_CONTROLLERS = "SET_CONTROLLERS";
export const RESET_CONTROLLERS = "RESET_CONTROLLERS";
export const CONTROLLERS_LOADED = "CONTROLLERS_LOADED";
export const UPDATE_CONTROLLER_POINTS = "UPDATE_CONTROLLER_POINTS";
export const SET_ALERT = "SET_ALERT";
export const UPDATE_ALERT_FROM_STREAM = "UPDATE_ALERT_FROM_STREAM";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const UPDATE_LAST_GATEWAY_STATUS_FROM_STREAM =
  "UPDATE_LAST_GATEWAY_STATUS_FROM_STREAM";
export const UPDATE_GATEWAY_FROM_STREAM = "UPDATE_GATEWAY_FROM_STREAM";

export const API_CALL_STARTED = "API_CALL_STARTED";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";

export const SET_USER = "SET_USER";

export const UPDATE_CONTROLLER_DETAILS = "UPDATE_CONTROLLER_DETAILS";
export const UPDATE_GATEWAY_CONTROLLERS = "UPDATE_GATEWAY_CONTROLLERS";
export const SET_GATEWAY_CONTROLLERS = "SET_GATEWAY_CONTROLLERS";

export const SET_PARAMETER_FILE_LIST = "SET_PARAMETER_FILE_LIST";

export const SET_LANGUAGES = "SET_LANGUAGES";

// _______ _________ _______  _        _______  _        _______
// (  ____ \\__   __/(  ____ \( (    /|(  ___  )( \      (  ____ )
// | (    \/   ) (   | (    \/|  \  ( || (   ) || (      | (    )|
// | (_____    | |   | |      |   \ | || (___) || |      | (____)|
// (_____  )   | |   | | ____ | (\ \) ||  ___  || |      |     __)
//       ) |   | |   | | \_  )| | \   || (   ) || |      | (\ (
// /\____) |___) (___| (___) || )  \  || )   ( || (____/\| ) \ \__
// \_______)\_______/(_______)|/    )_)|/     \|(_______/|/   \__/
//

export const updateSignalRState = (sigRState) => ({
  type: UPDATE_SIGNALR_STATE,
  payload: sigRState,
});

// _________          _______  _______  _______           _______  _        ______
// \__   __/|\     /|(  ____ )(  ____ \(  ____ \|\     /|(  ___  )( \      (  __  \
//    ) (   | )   ( || (    )|| (    \/| (    \/| )   ( || (   ) || (      | (  \  )
//    | |   | (___) || (____)|| (__    | (_____ | (___) || |   | || |      | |   ) |
//    | |   |  ___  ||     __)|  __)   (_____  )|  ___  || |   | || |      | |   | |
//    | |   | (   ) || (\ (   | (            ) || (   ) || |   | || |      | |   ) |
//    | |   | )   ( || ) \ \__| (____/\/\____) || )   ( || (___) || (____/\| (__/  )
//    )_(   |/     \||/   \__/(_______/\_______)|/     \|(_______)(_______/(______/
//

export const updateLastGatewayStatusFromSignalR = (status) => ({
  type: UPDATE_LAST_GATEWAY_STATUS_FROM_STREAM,
  payload: status,
});

export const updateGatewayFromSignalR = (status) => ({
  type: UPDATE_GATEWAY_FROM_STREAM,
  payload: status,
});

export const updateAlertFromSignalR = (alert) => ({
  type: UPDATE_ALERT_FROM_STREAM,
  payload: alert,
});

export const setAlerts = (alerts) => ({
  type: SET_ALERT,
  payload: alerts,
});

export const removeAlert = (alerts) => ({
  type: REMOVE_ALERT,
  payload: alerts,
});

// _______  _______ _________
// (  ___  )(  ____ )\__   __/
// | (   ) || (    )|   ) (
// | (___) || (____)|   | |
// |  ___  ||  _____)   | |
// | (   ) || (         | |
// | )   ( || )      ___) (___
// |/     \||/       \_______/

var apiID = 0;
export const apiAction =
  (apiFunction, apiFunctionPars, nextAction, skip = false) =>
  (dispatch) => {
    apiID += 1;
    dispatch(apiCallStarted(apiID));
    apiFunction(...apiFunctionPars)
      .then((res) => {
        if (skip || res.status === 200 || res.status === 201) {
          if (nextAction) {
            dispatch(nextAction(skip ? res : res.data));
          }
          dispatch(apiCallSuccess(skip ? res : res.data));
        } else dispatch(apiCallError(res.statusText));
      })
      .catch(() => dispatch(apiCallError()));
  };

export const apiCallStarted = (id) => ({
  type: API_CALL_STARTED,
  id,
});

export const apiCallSuccess = (json) => ({
  type: API_SUCCESS,
  receivedAt: Date.now(),
  json,
});

export const apiCallError = (errorText) => ({
  type: API_ERROR,
  errorText,
});

//  _______           _______ _________ _______  _______  _______  _______ 
// (  ____ \|\     /|(  ____ \\__   __/(  ___  )(       )(  ____ \(  ____ )
// | (    \/| )   ( || (    \/   ) (   | (   ) || () () || (    \/| (    )|
// | |      | |   | || (_____    | |   | |   | || || || || (__    | (____)|
// | |      | |   | |(_____  )   | |   | |   | || |(_)| ||  __)   |     __)
// | |      | |   | |      ) |   | |   | |   | || |   | || (      | (\ (   
// | (____/\| (___) |/\____) |   | |   | (___) || )   ( || (____/\| ) \ \__
// (_______/(_______)\_______)   )_(   (_______)|/     \|(_______/|/   \__/

export const setCustomers = (customers) => ({
  type: SET_CUSTOMERS,
  payload: customers,
});

// _______ __________________ _______  _______
// (  ____ \\__   __/\__   __/(  ____ \(  ____ \
// | (    \/   ) (      ) (   | (    \/| (    \/
// | (_____    | |      | |   | (__    | (_____
// (_____  )   | |      | |   |  __)   (_____  )
//       ) |   | |      | |   | (            ) |
// /\____) |___) (___   | |   | (____/\/\____) |
// \_______)\_______/   )_(   (_______/\_______)
//
export const setSites = (sites) => ({
  type: SET_SITES,
  payload: sites,
});

export const resetSites = () => ({
  type: RESET_SITES,
});

export const setSitesLoaded = (status = true) => ({
  type: SITES_LOADED,
  payload: status,
});

export const updateSite = (site) => ({
  type: SITE_UPDATE,
  payload: site,
});

// _______  _______ _________ _______           _______
// (  ____ \(  ___  )\__   __/(  ____ \|\     /|(  ___  )|\     /|
// | (    \/| (   ) |   ) (   | (    \/| )   ( || (   ) |( \   / )
// | |      | (___) |   | |   | (__    | | _ | || (___) | \ (_) /
// | | ____ |  ___  |   | |   |  __)   | |( )| ||  ___  |  \   /
// | | \_  )| (   ) |   | |   | (      | || || || (   ) |   ) (
// | (___) || )   ( |   | |   | (____/\| () () || )   ( |   | |
// (_______)|/     \|   )_(   (_______/(_______)|/     \|   \_/
//
export const setGateways = (gateway) => ({
  type: SET_GATEWAYS,
  payload: gateway,
});

export const resetGateways = () => ({
  type: RESET_GATEWAYS,
});

export const updateGateway = (gateway) => ({
  type: GATEWAY_UPDATE,
  payload: gateway,
});

export const updateGatewayControllers = (controllers) => ({
  type: UPDATE_GATEWAY_CONTROLLERS,
  payload: controllers,
});

export const setGatewayControllers = (controllers) => ({
  type: SET_GATEWAY_CONTROLLERS,
  payload: controllers,
});

//  _______  _______  _       _________ _______  _______  _        _        _______  _______  _______
// (  ____ \(  ___  )( (    /|\__   __/(  ____ )(  ___  )( \      ( \      (  ____ \(  ____ )(  ____ \
// | (    \/| (   ) ||  \  ( |   ) (   | (    )|| (   ) || (      | (      | (    \/| (    )|| (    \/
// | |      | |   | ||   \ | |   | |   | (____)|| |   | || |      | |      | (__    | (____)|| (_____
// | |      | |   | || (\ \) |   | |   |     __)| |   | || |      | |      |  __)   |     __)(_____  )
// | |      | |   | || | \   |   | |   | (\ (   | |   | || |      | |      | (      | (\ (         ) |
// | (____/\| (___) || )  \  |   | |   | ) \ \__| (___) || (____/\| (____/\| (____/\| ) \ \__/\____) |
// (_______/(_______)|/    )_)   )_(   |/   \__/(_______)(_______/(_______/(_______/|/   \__/\_______)

export const setControllers = (controller) => ({
  type: SET_CONTROLLERS,
  payload: controller,
});

export const updateControllersFromSignalR = (points) => ({
  type: UPDATE_CONTROLLER_POINTS,
  payload: points,
});

export const resetControllers = () => ({
  type: RESET_CONTROLLERS,
});

export const setControllersLoaded = (status = true) => ({
  type: CONTROLLERS_LOADED,
  payload: status,
});

export const updateControllerDetails = (controller) => ({
  type: UPDATE_CONTROLLER_DETAILS,
  payload: controller,
});

//  _        _______          _________ _______  _______ __________________ _______  _
// ( (    /|(  ___  )|\     /|\__   __/(  ____ \(  ___  )\__   __/\__   __/(  ___  )( (    /|
// |  \  ( || (   ) || )   ( |   ) (   | (    \/| (   ) |   ) (      ) (   | (   ) ||  \  ( |
// |   \ | || (___) || |   | |   | |   | |      | (___) |   | |      | |   | |   | ||   \ | |
// | (\ \) ||  ___  |( (   ) )   | |   | | ____ |  ___  |   | |      | |   | |   | || (\ \) |
// | | \   || (   ) | \ \_/ /    | |   | | \_  )| (   ) |   | |      | |   | |   | || | \   |
// | )  \  || )   ( |  \   /  ___) (___| (___) || )   ( |   | |   ___) (___| (___) || )  \  |
// |/    )_)|/     \|   \_/   \_______/(_______)|/     \|   )_(   \_______/(_______)|/    )_)
//

export const updateCurrentPage = (currentLocation = null) => ({
  type: UPDATE_CURRENT_PAGE,
  currentLocation,
});

//           _______  _______  _______
// |\     /|(  ____ \(  ____ \(  ____ )
// | )   ( || (    \/| (    \/| (    )|
// | |   | || (_____ | (__    | (____)|
// | |   | |(_____  )|  __)   |     __)
// | |   | |      ) || (      | (\ (
// | (___) |/\____) || (____/\| ) \ \__
// (_______)\_______)(_______/|/   \__/

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

//  _______  _______  _______  _______  _______  _______ _________ _______  _______
// (  ____ )(  ___  )(  ____ )(  ___  )(       )(  ____ \\__   __/(  ____ \(  ____ )
// | (    )|| (   ) || (    )|| (   ) || () () || (    \/   ) (   | (    \/| (    )|
// | (____)|| (___) || (____)|| (___) || || || || (__       | |   | (__    | (____)|
// |  _____)|  ___  ||     __)|  ___  || |(_)| ||  __)      | |   |  __)   |     __)
// | (      | (   ) || (\ (   | (   ) || |   | || (         | |   | (      | (\ (
// | )      | )   ( || ) \ \__| )   ( || )   ( || (____/\   | |   | (____/\| ) \ \__
// |/       |/     \||/   \__/|/     \||/     \|(_______/   )_(   (_______/|/   \__/

export const setParameterFileList = (payload) => ({
  type: SET_PARAMETER_FILE_LIST,
  payload: payload,
});

// _________ _______  _______  _        _______  _        _______ __________________ _______  _
// \__   __/(  ____ )(  ___  )( (    /|(  ____ \( \      (  ___  )\__   __/\__   __/(  ___  )( (    /|
//    ) (   | (    )|| (   ) ||  \  ( || (    \/| (      | (   ) |   ) (      ) (   | (   ) ||  \  ( |
//    | |   | (____)|| (___) ||   \ | || (_____ | |      | (___) |   | |      | |   | |   | ||   \ | |
//    | |   |     __)|  ___  || (\ \) |(_____  )| |      |  ___  |   | |      | |   | |   | || (\ \) |
//    | |   | (\ (   | (   ) || | \   |      ) || |      | (   ) |   | |      | |   | |   | || | \   |
//    | |   | ) \ \__| )   ( || )  \  |/\____) || (____/\| )   ( |   | |   ___) (___| (___) || )  \  |
//    )_(   |/   \__/|/     \||/    )_)\_______)(_______/|/     \|   )_(   \_______/(_______)|/    )_)

export const setLanguages = (payload) => ({
  type: SET_LANGUAGES,
  payload: payload,
});
