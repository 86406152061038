import {
  SET_GATEWAYS,
  GATEWAY_UPDATE,
  UPDATE_GATEWAY_FROM_STREAM,
  UPDATE_GATEWAY_CONTROLLERS,
  SET_GATEWAY_CONTROLLERS,
  RESET_GATEWAYS,
  UPDATE_LAST_GATEWAY_STATUS_FROM_STREAM,
} from "../actions/index";

const initialData = { byId: {}, allIds: [] };

const gateways = (state = initialData, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_GATEWAYS:
      return payload;
    case RESET_GATEWAYS:
      return initialData;
    case GATEWAY_UPDATE:
      let newState = { ...state };

      newState.byId[payload.gatewayGuid].name = payload.name;
      newState.byId[payload.gatewayGuid].telemetryPushInterval =
        payload.telemetryPushInterval;

      return newState;
    case UPDATE_LAST_GATEWAY_STATUS_FROM_STREAM:
      if (state.byId[payload.gatewayGuid] === undefined) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.gatewayGuid]: {
            ...state.byId[payload.gatewayGuid],
            lastStatus: payload.status,
          },
        },
      };
    case UPDATE_GATEWAY_FROM_STREAM:
      if (state.byId[payload.gatewayGuid] === undefined) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.gatewayGuid]: {
            ...state.byId[payload.gatewayGuid],
            status: payload.status === 1 ? "ON" : "OFF",
            currentDateTime: payload.currentDateTime,
            lastDataSentOn: payload.lastSeenOn,
            releaseFW: payload.releaseFW,
          },
        },
      };
    case UPDATE_GATEWAY_CONTROLLERS:
      let gateways = state;
      const { gatewayGuid, deviceGuid, friendlyName } = payload;

      gateways.byId[gatewayGuid].devices.forEach((device) => {
        if (device.deviceGuid === deviceGuid)
          device.friendlyName = friendlyName;
      });

      return gateways;
    case SET_GATEWAY_CONTROLLERS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.gatewayGuid]: {
            ...state.byId[payload.gatewayGuid],
            devices: payload.controllers,
          },
        }
      }
    default:
      return state;
  }
};

export default gateways;
