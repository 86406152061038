import React from "react";
import {
  Button,
  Form,
  InputGroup,
  InputGroupText,
  Input,
  FormGroup,
} from "reactstrap";
import validator from "validator";
import utils from "utils";
import keys from "configs/constants";
import api from "services/backendService";

class AnalogThreshold extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      receiversError: false,
      receivers: this.props.receivers ? this.props.receivers.join("; ") : "",
      minValue:
        this.props.th && this.props.th.minValue ? this.props.th.minValue : "",
      maxValue:
        this.props.th && this.props.th.maxValue ? this.props.th.maxValue : "",
      rangePercentage:
        this.props.th && this.props.th.rangePercentage
          ? this.props.th.rangePercentage
          : "",
      minValueError: false,
      maxValueError: false,
      rangePercentageError: false,
    };

    this.originalState = JSON.parse(JSON.stringify(this.state));
  }

  onInputChange(evt) {
    let error = false;
    const target = evt.target;
    const name = target.name;
    const value = target.value;
    if (name === "receivers") {
      for (const r of utils.getAlertReceiversArray(value)) {
        if (validator.isEmail(r) === false) {
          error = true;
          break;
        }
      }
      this.setState({ [name]: value, receiversError: error });
    } else if (name === "rangePercentage") {
      this.setState({
        [name]: value,
        rangePercentageError: value < 1 || value > 99,
      });
    } else if (name === "minValue" || name === "maxValue") {
      const mininumValue = parseFloat(name === "minValue" ? value : this.state.minValue);
      const maximumValue = parseFloat(name === "maxValue" ? value : this.state.maxValue);

      const rangeError = !isNaN(mininumValue) && !isNaN(maximumValue) && mininumValue > maximumValue;

      this.setState({
        minValue: mininumValue,
        maxValue: maximumValue,
        minValueError: rangeError,
        maxValueError: rangeError,
      });
    } else this.setState({ [name]: value });
  }

  async onSaveChangesClicked(_evt) {
    let same = utils.checkDifferencesOnThreshold(
      this.state,
      this.originalState,
      true
    );

    const guid = this.props.th.thresholdGuid;
    if (this.props.onApplyClick) {
      if (!same.attributes) {
        let response = await api.editExistingThreshold({
          thresholdGuid: guid,
          rangePercentage: this.state.rangePercentage,
          minValue: this.state.minValue,
          maxValue: this.state.maxValue,
          fieldName: this.props.th.fieldName,
          label: this.props.th.label,
        });

        if (response.status !== 200) {
          // Reset to original values
          this.setState({
            minValue: this.originalState.minValue,
            maxValue: this.originalState.maxValue,
            rangePercentage: this.originalState.rangePercentage,
          });
        }
      }

      const receivers = utils.getAlertReceiversArray(this.state.receivers);

      if (!same.receivers) {
        if (receivers.length) {
          let thnotify = [];
          for (const r of receivers) {
            thnotify.push({
              email: r,
              thresholdGuid: guid,
              enableNotification: "ON",
            });
          }
          await api.deleteAllThresholdNotifyForThreshold(guid);
          let response = await api.createOrUpdateThresholdNotify(thnotify);
          if (response !== 200) {
            // Bad response (perhaps due to license limitation). Reload email list anyway
            try {
              let { data } = (await api.getAllThresholdNotify(guid)).data;

              let emailList = [];

              data.forEach((item) => {
                emailList.push(item.email);
              });

              this.setState({ receivers: emailList.join("; ") });
            } catch (ex) {}
          }
        } else {
          await api.deleteAllThresholdNotifyForThreshold(guid);
        }
      }
      this.props.onApplyClick();
    }

    this.originalState = JSON.parse(JSON.stringify(this.state));
  }

  render() {
    let same = utils.checkDifferencesOnThreshold(
      this.state,
      this.originalState,
      true
    );

    return (
      <Form>
        <FormGroup>
          <InputGroup className="mb-3">
            <InputGroupText>{keys.ICON_MIN}</InputGroupText>
            <Input
              placeholder={this.props.t("deviceThresholds.minimumValue")}
              name="minValue"
              type="number"
              onChange={this.onInputChange.bind(this)}
              value={this.state.minValue}
              className={
                this.state.minValueError ? " bg-warning text-white " : null
              }
            />
            <InputGroupText>{keys.ICON_MAX}</InputGroupText>
            <Input
              placeholder={this.props.t("deviceThresholds.maximumValue")}
              name="maxValue"
              type="number"
              onChange={this.onInputChange.bind(this)}
              value={this.state.maxValue}
              className={
                this.state.maxValueError ? " bg-warning text-white " : null
              }
            />
            <InputGroupText>{keys.ICON_PERCENT}</InputGroupText>
            <Input
              placeholder={this.props.t(
                "deviceThresholds.returnToNormalPercentage"
              )}
              name="rangePercentage"
              type="number"
              onChange={this.onInputChange.bind(this)}
              value={this.state.rangePercentage}
              className={
                this.state.rangePercentageError
                  ? " bg-warning text-white "
                  : null
              }
            />
          </InputGroup>
        </FormGroup>

        <InputGroup className="mb-3">
          <InputGroupText>{keys.ICON_RECEIVERS}</InputGroupText>
          <Input
            placeholder={this.props.t(
              "deviceThresholds.multipleReceiversPlaceHolder"
            )}
            name="receivers"
            type="text"
            onChange={this.onInputChange.bind(this)}
            value={this.state.receivers}
            className={
              this.state.receiversError ? " bg-warning text-white " : null
            }
          />
        </InputGroup>
        {this.props.noButton !== true ? (
          <div className="text-center">
            <Button
              className="ml-md-5 btn-icon"
              color="primary"
              type="button"
              disabled={
                this.state.receiversError ||
                this.state.minValueError || this.state.maxValueError ||
                ( this.state.minValue === "" && this.state.maxValue === "" ) ||
                this.state.rangePercentage === "" ||
                this.state.rangePercentageError ||
                (same.receivers && same.attributes)
              }
              onClick={this.onSaveChangesClicked.bind(this)}
            >
              <span className="btn-inner--icon">{keys.ICON_SAVE}</span>
              <span className="btn-inner--text">
                {this.props.t("deviceThresholds.apply")}
              </span>
            </Button>
          </div>
        ) : null}
      </Form>
    );
  }
}

export default AnalogThreshold;
