import React, { Component } from "react";
import Loader from "../Loader";
import {
  Table,
  Collapse,
  Button,
  InputGroup,
  InputGroupText,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import validator from "validator";
import ListDropdown from "../../components/ListDropdown";
import { TooltipButton } from "../../components/Tooltip";
import api, { WX_FUNCTIONS, sources } from "../../services/backendService";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { bindActionCreators } from "redux";
import keys from "configs/constants";
import utils from "../../utils";
import "./parameters.css";
import { withTranslation } from "react-i18next";
import {AuthorizationContext} from "../../contexts/AuthorizationContext";

const NOT_AVAILABLE = "@0";
const AVAILABLE = "@1";
const ONLY_ADMIN = "@2";

const PR1 = "Pr1";
const PR2 = "Pr2";
const NV = "N.V.";

class Parameters extends Component {
  static contextType = AuthorizationContext;
  
  constructor(props) {
    super(props);

    this.visibility = [];
    this.rules = {};

    this.state = {
      deviceStatus: undefined,
      isLoading: true,
      parameters: [],
      filters: {
        error: false,
        search: "",
        changed: false,
        fVis: {},
      },
      lastRefresh: null,
      groupList: {},
      updateRequest: false,
      showModal: false,
      inclusiveFilter: false,
      showConfirmWrite: 0, // 0: Hide Modal, 1: Write ALL parameters, 2: Write CHANGED parameters
    };

    this.parChangedList = [];

    this.id = null;
  }

  componentDidMount() {
    this.updateParList();
  }

  componentWillUnmount() {
    if (sources[this.id]) sources[this.id].source.cancel();
  }

  componentDidUpdate = (prevProps) => {
    const { parametersInfo, device } = this.props;
    const fileName = device.address + "-" + device.model;
    const { deviceStatus } = this.state;

    if (
      parametersInfo !== undefined &&
      prevProps.parametersInfo !== parametersInfo &&
      parametersInfo.gatewayMacAddress === device.macAddress
    ) {
      if (
        this.state.updateRequest &&
        parametersInfo.fileName === fileName + ".map"
      ) {
        this.updateParList();
      }
    }

    if (deviceStatus !== this.checkDeviceStatus()) {
      this.setState({ deviceStatus: !deviceStatus });
    }
  };

  parseParameterList = (data) => {
    if (data !== undefined) {
      let parameters = data.parameters !== undefined ? data.parameters : data;
      let { filters } = this.state;

      let { groupList } = this.state;
      for (let i = 0; i < parameters.length; i++) {
        parameters[i].key = i;
        parameters[i].prevValue = parameters[i].v;
        parameters[i].prevVisibility = parameters[i].vis;
        parameters[i].prevLowerLimit = parameters[i].ll;
        parameters[i].prevUpperLimit = parameters[i].ul;
        parameters[i].prevMeasureUnit = parameters[i].mu;
        parameters[i].typeInfo = null;

        groupList = {
          ...groupList,
          [parameters[i].g]: false,
        };
        if (parameters[i].t === "L" && parameters[i].vlb === undefined) {
          parameters[i].e = 3;
        }
      }

      let groupListArr = Object.keys(groupList).sort();

      groupListArr.unshift("Clear Filters");

      groupList = {};

      for (let group of groupListArr) {
        groupList = {
          ...groupList,
          [group]: false,
        };
      }

      this.visibility =
        data.visibility !== undefined ? data.visibility : [PR1, PR2, NV];

      for (let vis of this.visibility) {
        filters.fVis[vis] = false;
      }

      this.rules = data.rules !== undefined ? data.rules : undefined;

      this.setState({
        parameters,
        groupList,
        filters,
        isLoading: false,
        updateRequest: false,
      });
    }
  };

  updateParList = () => {
    const { device } = this.props;
    const { macAddress } = device;
    const fileName = device.address + "-" + device.model;

    api
      .parameterMap(this.props.deviceGuid, WX_FUNCTIONS.READ)
      .then((response) => {
        this.id = response.id;

        response.execute
          .then((map) => {
            api
              .getAvailableParameterFiles(macAddress, fileName + ".map")
              .then((response) => {
                this.id = response.id;

                response.execute
                  .then((files) => {
                    this.parseParameterList(map.data);

                    for (let file of files.data.data) {
                      if (file.name === macAddress + "/" + fileName + ".map") {
                        this.setState({ lastRefresh: file.lastModified });
                        break;
                      }
                    }
                  })
                  .catch((e) => {
                    this.quitRequest(e);
                  });
              })
              .catch((e) => {
                this.quitRequest(e);
              });
          })
          .catch((e) => {
            this.quitRequest(e);
          });

        this.setState({ deviceStatus: this.checkDeviceStatus() });
      })
      .catch((e) => {
        this.quitRequest(e);
      });
  };

  quitRequest = (e) => {
    if (e.toString() !== "Cancel") {
      this.setState({
        isLoading: false,
        updateRequest: false,
      });
    }
  };

  checkDeviceStatus = () => {
    const { gatewayGuid } = this.props.gatewayInfo;
    const { deviceGuid } = this.props;
    const { points } = this.props.device;
    const alerts = this.props.alerts.byId;

    let status = true;

    if (alerts[gatewayGuid] !== undefined) {
      if (alerts[gatewayGuid][deviceGuid] !== undefined) {
        for (let alert of alerts[gatewayGuid][deviceGuid]) {
          if (alert.fieldName.toLowerCase() === "nolink") status = false;
        }
      }
    }

    if (status) {
      // NoLink is not active but the device may still be disconnected,
      // maybe because noLink is not configured as alarm
      // If there's no good points, the device is disconnected

      let goodPoints = Object.keys(points).filter((p) => {
        let result = utils.pointValue(
          points[p].value || points[p].lastValue,
          points[p].type,
          points[p].vtype
        );

        if (result !== "NA" && result !== "ERROR") return true;
        return false;
      });

      status = goodPoints.length > 0;
    }

    return status;
  };

  forgetChanges = () => {
    let { parameters } = this.state;

    for (let par of parameters) {
      par.v = par.prevValue;
      par.vis = par.prevVisibility;
      par.ll = par.prevLowerLimit;
      par.ul = par.prevUpperLimit;
      par.mu = par.prevMeasureUnit;
      delete par.parChanged;
      delete par.visChanged;
      par.e = this.checkParameterRange(par.t, par.prevValue, par.ul, par.ll)
        ? 2
        : par.t === "L" && par.vlb === undefined
        ? 3
        : 0;
    }

    this.parChangedList = [];

    this.setState({ parameters, showModal: false });
  };

  checkParameterRange = (type, value, upperLimit, lowerLimit) => {
    if (upperLimit === undefined || lowerLimit === undefined) return false;
    if (type === "L") {
      return false;
    } else {
      if (
        parseFloat(value) >= parseFloat(lowerLimit) &&
        parseFloat(value) <= parseFloat(upperLimit) &&
        validator.isFloat(value)
      )
        return false;
      else {
        return true;
      }
    }
  };

  onChangeValue = async (e, i, vis = false) => {
    const { value } = e.target;

    let { parameters } = this.state;

    if (!vis) parameters[i].v = value;
    else parameters[i].vis = value;

    this.setState({ parameters });

    clearTimeout(this._request);

    this._request = setTimeout(async () => {
      await requestPar(i, vis);
    }, 200);

    const requestPar = async (i, vis = false) => {
      let { parameters } = this.state;

      let index = 0;
      for (let cPars of this.parChangedList) {
        if (
          cPars.key === i ||
          (!vis && cPars.v === cPars.prevValue) ||
          (vis && cPars.vis === cPars.prevVisibility)
        ) {
          this.parChangedList.splice(index, 1);
        }

        index++;
      }

      this.parChangedList.push(parameters[i]);

      try {
        let response = await api.parameterMap(
          this.props.deviceGuid,
          WX_FUNCTIONS.EDIT,
          this.parChangedList
        );

        response.execute
          .then((data) => {
            let map = data.data;

            let idx = 0;
            for (let par of map.parameters) {
              parameters[idx].ul = par.ul;
              parameters[idx].ll = par.ll;
              parameters[idx].mu = par.mu;
              parameters[idx].e = par.e;

              parameters[idx].parChanged =
                parameters[idx].v !== parameters[idx].prevValue;
              parameters[idx].visChanged =
                parameters[idx].vis !== parameters[idx].prevVisibility;

              idx++;
            }

            this.setState({ parameters });
          })
          .catch(() => {});
      } catch (e) {}
    };
  };

  renderParValue = (par, i, isAdmin = false) => {
    switch (par.t) {
      case "L":
        if (par.vlb === undefined) {
        }
        return (
          <select
            className={`par-label-list bg-transparent ${
              par.e > 0 || par.parChanged === true ? "text-white" : ""
            }`}
            value={par.v}
            onChange={(e) => {
              //this.setState({ isLoading: true });
              this.onChangeValue(e, i)
                .then(() => this.setState({ isLoading: false }))
                .catch(() => this.setState({ isLoading: false }));
            }}
          >
            {par.vlb === undefined
              ? null
              : par.vlb.map((val, i) => {
                  return <option key={i}>{val}</option>;
                })}
          </select>
        );
      default:
        let value = par.v;
        let { typeInfo } = par;

        if (typeInfo !== null) {
          if (typeInfo.n === 0) value = value.split(".")[0];
        }

        return (
          <input
            t="text"
            className={`par-value bg-transparent ${
              par.e > 0 || par.parChanged === true || par.edi !== AVAILABLE
                ? "text-white"
                : ""
            }`}
            value={par.edi === NOT_AVAILABLE ? "" : value}
            onChange={(e) => {
              //this.setState({ isLoading: true });
              this.onChangeValue(e, i)
                .then(() => this.setState({ isLoading: false }))
                .catch(() => this.setState({ isLoading: false }));
            }}
            disabled={
              par.edi === NOT_AVAILABLE || (par.edi === ONLY_ADMIN && !isAdmin)
            }
          />
        );
    }
  };

  renderVisibility = (par, i) => {
    if (par.vis.includes("@")) return null;
    return (
      <select
        className={`par-label-list bg-transparent ${
          par.visChanged ? "text-white" : ""
        }`}
        value={par.vis}
        onChange={(e) => this.onChangeValue(e, i, true)}
      >
        {this.visibility.map((val, i) => {
          return <option key={i}>{val}</option>;
        })}
      </select>
    );
  };

  togglePar = (i) => {
    let { parameters } = this.state;

    parameters[i].isOpen = !parameters[i].isOpen;

    this.setState(() => {
      return { parameters: parameters };
    });
  };

  writeParameters = (all) => {
    let { parameters } = this.state;
    if (!all) {
      parameters = parameters.filter((par) => par.parChanged || par.visChanged);
    }

    this.setState({ isLoading: true, showModal: false });

    api
      .parameterMap(this.props.deviceGuid, WX_FUNCTIONS.WRITE, parameters, all)
      .then((response) => {
        this.id = response.id;

        response.execute
          .then(() => {
            this.setState({ updateRequest: true });
          })
          .catch((e) => {
            if (e.toString() !== "Cancel")
              this.setState({ isLoading: false, updateRequest: false });
          });
      })
      .catch((e) => {
        if (e.toString() !== "Cancel")
          this.setState({ isLoading: false, updateRequest: false });
      });
  };

  exportParameters = async (e) => {
    this.setState({ isLoading: true, showModal: false });
    try {
      const fType = e.target.children[0].innerText.toLowerCase();
      const { deviceGuid } = this.props;

      let devName = this.props.address + " - " + this.props.device.model;

      const deviceInfo = this.props.gatewayInfo.devices.filter(
        (x) => x.deviceGuid === this.props.deviceGuid
      )[0];

      if (deviceInfo.friendlyName && deviceInfo.friendlyName !== "")
        devName += " - " + deviceInfo.friendlyName;

      let data = await api.exportParametersList([deviceGuid], fType);

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        devName + "." + fType + (fType === "xls" ? "x" : "")
      );
      link.className = "d-none";
      document.body.appendChild(link);
      link.click();
    } catch (e) {}

    this.setState({
      isLoading: false,
    });
  };

  renderButtons = (
    errors,
    changes,
    countFilters,
    groups,
    readOnly,
    modal = false
  ) => {
    let filterVis = [
      {
        checkbox: true,
        text: this.props.t("parameters.parametersInError"),
        checkboxStatus: this.state.filters.error,
        command: () => {
          let { filters } = this.state;
          filters.error = !filters.error;
          this.setState({ filters });
        },
      },
      {
        checkbox: true,
        text: this.props.t("parameters.changedParameters"),
        checkboxStatus: this.state.filters.changed,
        command: () => {
          let { filters } = this.state;
          filters.changed = !filters.changed;
          this.setState({ filters });
        },
      },
      {
        checkbox: false,
        text: this.props.t("parameters.visibility"),
        header: true,
        disabled: true,
      },
    ];

    for (let vis in this.state.filters.fVis) {
      let newFilter = {
        checkbox: true,
        text: vis,
        checkboxStatus: this.state.filters.fVis[vis],
        command: () => {
          let { filters } = this.state;
          filters.fVis[vis] = !filters.fVis[vis];
          this.setState({ filters });
        },
      };
      filterVis.push(newFilter);
    }

    return (
      <div className={`${!modal ? "mt-4" : "par-actions"}`}>
        <Button
          id="refreshList"
          className="btn-parameters"
          color="primary"
          onClick={async () => {
            this.setState({
              isLoading: true,
              updateRequest: true,
              showModal: false,
            });
            await api.updateParameterMap(this.props.gatewayInfo.gatewayGuid, [
              this.props.address,
            ]);
          }}
          disabled={
            !this.state.deviceStatus || this.props.gatewayInfo.status === "OFF"
          }
        >
          {this.props.t("parameters.refreshList")}
          <TooltipButton
            target="refreshList"
            text={this.props.t("parameters.refreshListToolTip")}
          />
        </Button>
        <ListDropdown
          color="primary"
          disabled={this.state.parameters.length === 0 || this.state.isLoading}
          title={this.props.t("parameters.mapManage")}
          listItems={[
            {
              text: this.props.t("parameters.downloadMap"),
              disabled:
                this.state.parameters.length === 0 || this.state.isLoading,
              command: () => {
                const { device } = this.props;
                const devName = device.address + "-" + device.model;

                this.setState({ isLoading: true, showModal: false });

                changes = this.state.parameters.filter(
                  (par) => par.parChanged || par.visChanged
                );

                api
                  .downloadMap(device.macAddress, devName, changes, "bin")
                  .then((data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", devName + ".bin");
                    link.className = "d-none";
                    document.body.appendChild(link);
                    link.click();

                    this.setState({
                      isLoading: false,
                    });
                  })
                  .catch(() =>
                    this.setState({
                      isLoading: false,
                    })
                  );
              },
            },
            {
              text: this.props.t("parameters.uploadMap") + "...",
              disabled: this.state.isLoading,
              command: () => {
                this.setState({ showModal: false });
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", ".bin");

                input.addEventListener("input", async () => {
                  this.setState({ isLoading: true });
                  let formData = new FormData();
                  formData.append("mapFile", input.files[0]);

                  api
                    .uploadMap(this.props.deviceGuid, formData)
                    .then((callback) => {
                      callback.execute
                        .then((r) => {
                          const newList = r.data.parameters;
                          let { parameters } = this.state;

                          parameters.forEach((par) => {
                            const newPar = newList.filter(
                              (n) => n.l === par.l
                            )[0];

                            if (par.mu !== newPar.mu) par.mu = newPar.mu;

                            if (par.v !== newPar.v) {
                              par.v = newPar.v;
                              par.parChanged = true;
                            }

                            if (par.vis !== newPar.vis) {
                              par.vis = newPar.vis;
                              par.visChanged = true;
                            }
                          });

                          this.setState({ parameters });
                          this.setState({ isLoading: false });
                        })
                        .catch(() => {
                          this.setState({ isLoading: false });
                        });
                    });
                });

                input.click();
              },
            },
          ]}
        />
        <ListDropdown
          disabled={this.state.parameters.length === 0}
          title={this.props.t("parameters.exportParameters")}
          listItems={[
            {
              text: "PDF",
              command: this.exportParameters,
              disabled:
                this.state.isLoading || this.state.parameters.length === 0,
            },
            {
              text: "XLS",
              command: this.exportParameters,
              disabled:
                this.state.isLoading || this.state.parameters.length === 0,
            },
          ]}
        ></ListDropdown>
        <Button
          id="writeAll"
          className="btn-parameters"
          color={`${errors > 0 ? "danger" : "primary"}`}
          disabled={
            readOnly ||
            !this.state.deviceStatus ||
            this.state.parameters.length === 0 ||
            this.props.gatewayInfo.status === "OFF"
          }
          onClick={() => {
            if (errors === 0) this.writeParameters(true);
            else this.setState({ showConfirmWrite: 1 });
          }}
        >
          {this.props.t("parameters.writeAll")}
          <TooltipButton
            target="writeAll"
            text={this.props.t("parameters.writeAllToolTip")}
          />
        </Button>
        <Button
          id="writeChanges"
          className="btn-parameters"
          color={`${errors > 0 ? "danger" : "primary"}`}
          disabled={
            !changes ||
            readOnly ||
            !this.state.deviceStatus ||
            this.state.parameters.length === 0 ||
            this.props.gatewayInfo.status === "OFF"
          }
          onClick={() => {
            if (errors === 0) this.writeParameters(false);
            else this.setState({ showConfirmWrite: 2 });
          }}
        >
          {this.props.t("parameters.writeChanges")}
          <TooltipButton
            target="writeChanges"
            text={this.props.t("parameters.writeChangesToolTip")}
          />
        </Button>
        <Button
          id="forgetChanges"
          className="btn-parameters"
          color="primary"
          disabled={!changes || !this.state.deviceStatus}
          onClick={this.forgetChanges}
        >
          {this.props.t("parameters.forgetChanges")}
          <TooltipButton
            target="forgetChanges"
            text={this.props.t("parameters.forgetChangesToolTip")}
          />
        </Button>
        <ListDropdown
          className="btn-parameters"
          title={
            this.props.t("parameters.filters") +
            `${countFilters === 0 ? "..." : " (" + countFilters + ")"}`
          }
          listItems={filterVis}
        />
        <ListDropdown
          className="btn-parameters"
          title={
            this.props.t("parameters.selectGroups") +
            `${groups.length === 0 ? "..." : " (" + groups.length + ")"}`
          }
          listItems={Object.keys(this.state.groupList).map((group) => {
            if (group === "Clear Filters")
              return {
                text: this.props.t("parameters.clearFilters"),
                command: () => {
                  let { groupList } = this.state;

                  for (let group of Object.keys(groupList)) {
                    groupList[group] = false;
                  }

                  this.setState({
                    groupList,
                  });
                },
              };
            return {
              checkbox: true,
              text: group,
              checkboxStatus: this.state.groupList[group],
              command: () => {
                let { groupList } = this.state;
                groupList[group] = !groupList[group];
                this.setState({ groupList });
              },
            };
          })}
        />
      </div>
    );
  };

  getParameterVisibility = (par) => {
    const vis = par.vis.toUpperCase();

    if (vis.includes("P") && vis.includes("R")) {
      if (vis.includes("1")) return PR1;
      if (vis.includes("2")) return PR2;
    } else if (vis.includes("N") && vis.includes("V")) return NV;

    return undefined;
  };

  render() {
    const readOnly = !this.context.hasWritePermission(this.props.gatewayInfo.gatewayGuid);
    
    const changes =
      this.state.parameters.filter((par) => par.parChanged || par.visChanged)
        .length > 0;

    const errors = this.state.parameters.filter((par) => par.e > 0).length;

    let { parameters } = this.state;

    if (!this.context.isSuperAdmin) {
      this.visibility = [PR1, PR2];

      // Remove unavailable parameters (configuration and status bits, not visible, Pr2 for viewer)
      parameters = parameters.filter(
        (par) =>
          par.vis !== NOT_AVAILABLE && this.getParameterVisibility(par) !== NV
      );

      if (readOnly) {
        this.visibility = [PR1];
        parameters = parameters.filter(
          (par) => this.getParameterVisibility(par) !== PR2
        );
      }
    }

    let groups = Object.keys(this.state.groupList).filter(
      (group) => this.state.groupList[group]
    );

    // Filter parameters
    parameters = parameters.filter((par) => {
      let activeFilters = 0;
      let returnValue = 0;
      if (this.state.filters.error) {
        activeFilters |= 1;
        if (par.e > 0) returnValue |= 1;
      }

      if (this.state.filters.changed) {
        activeFilters |= 2;
        if (par.parChanged || par.visChanged) returnValue |= 2;
      }

      if (this.state.filters.search !== "") {
        activeFilters |= 4;
        if (
          par.l.toUpperCase().includes(this.state.filters.search.toUpperCase())
        )
          returnValue |= 4;
      }

      if (groups.length > 0) {
        activeFilters |= 8;
        for (let group of groups) {
          if (par.g === group) returnValue |= 8;
        }
      }

      if (activeFilters === 0) return true;
      if (this.state.inclusiveFilter) {
        if (returnValue > 0) return true;
      } else {
        if (returnValue === activeFilters) return true;
      }
      return false;
    });

    let countVisFilters = 0;
    if (Object.keys(this.state.filters.fVis).length > 0) {
      for (let vis in this.state.filters.fVis) {
        if (this.state.filters.fVis[vis]) {
          countVisFilters += 1;
        }
      }
    }

    if (countVisFilters > 0) {
      parameters = parameters.filter((par) => {
        let found = false;
        for (let vis in this.state.filters.fVis) {
          if (this.state.filters.fVis[vis]) {
            if (
              par.vis === vis ||
              (vis.toLowerCase() === "pr1" && par.vis === AVAILABLE)
            )
              found = true;
          }
        }
        return found;
      });
    }

    let countFilters = 0;

    if (this.state.filters.error) countFilters++;
    if (this.state.filters.changed) countFilters++;
    countFilters += countVisFilters;

    return (
      <>
        {this.state.isLoading ? <Loader /> : null}

        <div className="par-buttons-wide">
          {this.renderButtons(errors, changes, countFilters, groups, readOnly)}
        </div>
        <Button
          className="btn-toggle-modal btn-block my-3"
          onClick={() => this.setState({ showModal: !this.state.showModal })}
          color="primary"
        >
          {this.props.t("parameters.parameterOptions")}
        </Button>
        <Modal
          centered
          isOpen={this.state.showModal}
          toggle={() => this.setState({ showModal: !this.state.showModal })}
        >
          <ModalBody>
            {this.renderButtons(
              errors,
              changes,
              countFilters,
              groups,
              readOnly,
              true
            )}
          </ModalBody>
        </Modal>

        <div className="mt-2">
          <span className={`par-error-count ${errors > 0 ? "" : "d-none"}`}>
            <span>{`Error count: `}</span>
            <span className="h4">{`${errors}`}</span>
          </span>
          <span
            className={`float-right ${
              this.state.lastRefresh !== null ? "" : "d-none"
            }`}
          >
            {this.props.t("parameters.lastRefresh") +
              `: ${new Date(this.state.lastRefresh).toLocaleString()}`}
          </span>
          <InputGroup className="mb-1">
            <InputGroupText>{keys.ICON_SEARCH}</InputGroupText>
            <Input
              placeholder={
                this.props.t("parameters.parameterName") +
                "..." +
                (!this.state.deviceStatus
                  ? " " + this.props.t("parameters.deviceUnlinked")
                  : "")
              }
              id="parameter"
              name="parameter"
              t="text"
              value={this.state.filters.search}
              onChange={(e) => {
                let { filters } = this.state;
                filters.search = e.target.value;
                this.setState({ filters });
              }}
            />
          </InputGroup>
          <div className="par-list">
            <Table bordered size="sm">
              <thead>
                <tr className="bg-info text-white">
                  <th>{this.props.t("parameters.group")}</th>
                  <th>{this.props.t("parameters.label")}</th>
                  <th>{this.props.t("parameters.description")}</th>
                  <th>{this.props.t("parameters.value")}</th>
                  {/* <th>Visibility</th>
                <th>Lower Limit</th>
                <th>Upper Limit</th> */}
                  <th>{this.props.t("parameters.unit")}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.parameters.length === 0 && !this.state.isLoading ? (
                  <tr>
                    <td colSpan="5">
                      {this.props.t("parameters.noParameterList")}
                    </td>
                  </tr>
                ) : (
                  parameters.map((par, i) => {
                    return (
                      <React.Fragment key={par.key}>
                        <tr
                          className={`par-item ${
                            i % 2 === 0 ? "btn-secondary" : "btn-white"
                          }`}
                        >
                          <td onClick={() => this.togglePar(par.key)}>
                            {par.g}
                          </td>
                          <td onClick={() => this.togglePar(par.key)}>
                            {par.l}
                          </td>
                          <td onClick={() => this.togglePar(par.key)}>
                            {par.d}
                          </td>
                          <td
                            className={`par-value-container ${
                              par.e > 0
                                ? "bg-danger"
                                : par.parChanged === true
                                ? "bg-success"
                                : par.edi === ONLY_ADMIN && !this.context.isSuperAdmin
                                ? "bg-light"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                par.edi === NOT_AVAILABLE ||
                                (par.edi === ONLY_ADMIN && !this.context.isSuperAdmin)
                              )
                                this.togglePar(par.key);
                            }}
                          >
                            {this.renderParValue(par, par.key, this.context.isSuperAdmin)}
                          </td>
                          {/* <td className={`${
                                  par.visChanged ? "bg-success text-white" : ""
                                }`}>{this.renderVisibility(par, i)}</td>
                  <td>{par.ll}</td>
                  <td>{par.ul}</td> */}
                          <td onClick={() => this.togglePar(par.key)}>
                            {par.mu}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="5" className="m-0 p-0">
                            <Collapse isOpen={par.isOpen}>
                              <table className="bg-white mx-5 my-2 float-right">
                                <tbody>
                                  <tr>
                                    <td>
                                      {this.props.t(
                                        "parameters.originalValue"
                                      ) + ": "}
                                    </td>
                                    <td
                                      className={`${
                                        parseFloat(par.prevValue) >
                                          parseFloat(par.ul) ||
                                        parseFloat(par.prevValue) <
                                          parseFloat(par.ll)
                                          ? "bg-danger text-white"
                                          : ""
                                      }`}
                                    >
                                      {par.prevValue}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {this.props.t("parameters.lowerLimit") +
                                        ": "}
                                    </td>
                                    <td
                                      className={`${
                                        parseFloat(par.ll) > parseFloat(par.ul)
                                          ? "bg-danger text-white"
                                          : ""
                                      }`}
                                    >
                                      {par.ll}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {this.props.t("parameters.upperLimit") +
                                        ": "}
                                    </td>
                                    <td
                                      className={`${
                                        parseFloat(par.ll) > parseFloat(par.ul)
                                          ? "bg-danger text-white"
                                          : ""
                                      }`}
                                    >
                                      {par.ul}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {this.props.t("parameters.visibility") +
                                        ": "}
                                    </td>
                                    <td
                                      className={`${
                                        par.visChanged
                                          ? "bg-success text-white"
                                          : ""
                                      }`}
                                    >
                                      {this.renderVisibility(par, par.key)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Collapse>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Modal
          centered
          isOpen={this.state.showConfirmWrite > 0}
          toggle={() => this.setState({ showConfirmWrite: 0 })}
        >
          <ModalBody>
            <div className="p-4 m-2">
              {this.props.t("parameters.errorsInMap")}
              <div className="confirm-write-buttons float-right">
                <Button
                  onClick={() => {
                    this.writeParameters(this.state.showConfirmWrite === 1);
                    this.setState({ showConfirmWrite: 0 });
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ showConfirmWrite: 0 });
                  }}
                >
                  No
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const getAlerts = createSelector(
  (state) => state.alerts,
  (alerts) => alerts
);

const getDeviceInfo = (state, ownProps) => {
  return {
    macAddress: ownProps.gatewayInfo.macAddress,
    model:
      state.controllers.byId[ownProps.deviceGuid] !== undefined
        ? state.controllers.byId[ownProps.deviceGuid].model
        : undefined,
    address:
      state.controllers.byId[ownProps.deviceGuid] !== undefined
        ? state.controllers.byId[ownProps.deviceGuid].address
        : undefined,
    points:
      state.controllers.byId[ownProps.deviceGuid] !== undefined
        ? state.controllers.byId[ownProps.deviceGuid].pointsById
        : undefined,
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    parametersInfo: state.parametersInfo,
    device: getDeviceInfo(state, ownProps),
    alerts: getAlerts(state),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withTranslation("common")(
  connect(mapStateToProps, mapDispatchToProps)(Parameters)
);
