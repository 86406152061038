import { setGatewayControllers, setControllers } from './index';
import api from 'services/backendService';
import utils from 'utils';

export const setControllersByGatewayGuidAsync = (gatewayGuid) => {
    return async (dispatch) => {
        try {
            const response = await api.getDevicesOnGateway(gatewayGuid);
            const controllers = Object.keys(response.byId).map((deviceGuid) => ({
                deviceGuid,
                model: response.byId[deviceGuid].model,
                friendlyName: response.byId[deviceGuid].friendlyName,
                isDongleController: response.byId[deviceGuid].isDongleController,
            }));

            dispatch(setGatewayControllers({ gatewayGuid, controllers }));
            dispatch(setControllers(response));
        } catch (error) {
            utils.debug(`Error in setControllersByGatewayGuidAsync: ${error}`, utils.MSG_TYPE_WARN);
        }
    };
}